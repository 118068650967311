.HPBusiness-Footer {
    background: url("../../Assets/img/footerBg.svg") no-repeat;
    background-size: cover;
    position: relative;
    bottom: 0;
    height: 300px;
    margin-top: 250px;
    z-index: 1 !important;
}

.HPBusiness-Footer {
    margin-top: 10px;
}
@media screen and (min-device-width: 2560px) and (max-device-width: 1080px) { 

    .HPBusiness-Footer {
        margin-top: 600px;
    }
}
@media screen and (min-device-width: 1921px) and (max-device-width: 3000px) {
    .HPBusiness-Footer {
        margin-top: 865px;
    }
}
@media screen and (max-device-width: 1680px) {
    .HPBusiness-Footer {
        padding-top: 0 !important;
    }
}
@media screen and (max-device-width: 1500px) {
    .HPBusiness-Footer {
        margin-top: 190px;
        height: 300px;
    }
}
@media screen and (max-device-width: 1280px) {
    .HPBusiness-Footer {
        margin-top: 222px;
        height: 259px;
    }
}
@media screen and (max-device-width: 1024px) {
    .HPBusiness-Footer {
        height: 217px;
        margin-top: 146px;
    }
}
@media screen and (max-device-width: 768px) {
    .HPBusiness-Footer {
        height: 245px;
        // margin-top: 40px;
        margin-bottom: 80px !important;
        padding-bottom: 80px !important;
    }
}
@media screen and (max-device-width: 375px) {
    .HPBusiness-Footer {
        margin-bottom: 50px !important;
        height: 130px;
    }
}
@media screen and (max-device-width: 414px) {
    .HPBusiness-Footer {
        margin-bottom: 61px !important;
        height: 140px;
    }
}
