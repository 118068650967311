$backgroundIwant: green;
@import "../../../Assets/css/variable";

.getTicketsDivv{
  display: flex !important;
  width: 150px;
}
.iconTooltipBanner {
  /* // border: solid black !important; */
  margin-top: 6px !important;
  margin-left: 4px;
}
.imageBox {
  width: 100px !important;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
}

.border {
  border-width: 1px;
  border-style: solid;
  border-color: black;
}

.iWantInBackground {
  background-color: $backgroundIwant;
}

.mapContainer {
  width: 0% !important;
  height: 0% !important;
  position: relative;
  top: 0;
}

.evtDetailsUsername{
  cursor: pointer;
  font-family: $fontBold;
  font-size:18px!important;
}

.eventTicketsInput{
  color:#fff!important;
  border-radius: 15px!important;
  background: #093d77 !important;
  font-size:12px!important;

}

.eventTicketsInput::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color:#fff!important;
}
.eventTicketsInput::-moz-placeholder { /* Firefox 19+ */
  color:#fff!important;
}
.eventTicketsInput:-ms-input-placeholder { /* IE 10+ */
  color:#fff!important;
}
.eventTicketsInput:-moz-placeholder { /* Firefox 18- */
  color:#fff!important;
}

//remove arrows from number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.greenBtnNew{
  background-color: #20b55c !important;
    color: white;
    border-radius: 50px;
    font-family: $fontBold;
    font-size: 14px!important;
    cursor: pointer;
    transition: 0.3s;
    border:none!important;
    text-transform: capitalize!important;
    border-radius: 50px!important;
    padding:5px 15px!important;

    &:hover{
      background-color: #1a9e4f !important;
    }



}
.greenBtnNew-tickets{
  background-color: #20b55c !important;
  color: white;
  border-radius: 50px;
  font-family: $fontBold;
  font-size: 14px!important;
  cursor: pointer;
  transition: 0.3s;
  border:none!important;
  text-transform: capitalize!important;
  border-radius: 50px!important;
  padding:5px 15px!important;

  &:hover{
    background-color: #1a9e4f !important;
  }

}
.sec-row-space{
  padding-bottom: 5px !important;
}
.cont-space{
  .EventLabelDescBg-tickets{
    background: #093d771c !important;
    border-radius: 5px;
  }
}
.price-clculat{
  width: 60px !important;
}
.RonSign-money{
  padding-top: 3px !important;

}
.lable-style{
  margin-bottom: 0 !important;
}
.Parentprice{
  display: flex !important;
}
.secound-kid{
padding-right: 0 !important;
margin-right: 0 !important;
flex-wrap: nowrap !important;
margin: 0 !important;
}
.parent-last-section{
  justify-content: space-between !important;
  // width: 50% !important;
  padding: 0 !important;
  .getTicketsBtn{
    width: 50%  !important;
    text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
padding-right: 0 !important;
padding-left: 0 !important;

  }
  .Total-Price-space{
    width:100% !important;
  }
}

@media screen and (max-device-width : 767px)
{
  .greenBtnNew-tickets{
    font-size: 11px !important;
    float: right ;
  }
}

.EventLabelDescBg{
  background: #093d771c;
  border-radius: 5px;
  // border-bottom: solid black !important;
}

.detailsEventUnderTitle{
  color:#fff!important;
  display: flex;
  justify-content: space-around;

  .EventLabel{
    color:#fff;
    margin-right:5px;
  }

  .EventLabelDesc{
    font-size: 15px;
    border-right: 1px solid #ffffff82;
    padding-right: 18px;
  }

}

.eventTopInfo{
  display: flex;
  align-items: center;
  background: #093d77 !important;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
}

.evtPopupPic{
  width:80px;
  height:80px;
  border-radius: 50%;
  object-fit: cover;
  transition: 2s;
  // position: absolute !important;
}

  .biggerSize{
    width: 600px !important;
    height: 600px !important;
    border-radius: 50% !important;
    justify-content: center !important;
    align-self: center !important;

  }
  .modalbody-center{

    align-self: center !important;
}

.greenBtnNew.round{
  border-radius: 50%!important;
  width:31px;
  height:30px;
  text-align: center;
  margin-right:10px;
  display: flex;
  justify-content: center;
}
.EventsTitle{
  font-size:22px;
  font-family: $fontBold;
  color: #13e567;

}

.EventLabelDesc{
  font-size: 15px;
}

.EventLabel{
  font-family: $fontBold;
  color: #2d5683;
}

.profileAlign{
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}


.teamMember {
  height: 50px !important;
  width: 50px !important;
  max-width: 50px!important;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.messageBox::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f5f5f5;
}

.messageBox::-webkit-scrollbar {
  border-radius: 5px;
  width: 5px;
  background-color: #f5f5f5;
}

.messageBox::-webkit-scrollbar-thumb {
  border-radius: 5px;
  // background-color: $blueevents;
}

.messageBox {
  height: 80px;
  overflow: auto;
  overflow-x: hidden;
  padding-left: 5px;
  padding-right: 15px;
}
.friends-cont{
justify-content: flex-start !important;
  display: flex !important;

  .friends-col{
    border: solid black;
    overflow: auto;
    overflow-y: scroll !important;
    background-color: red !important;
  }
}
.participants-parinte{
  justify-content: flex-start !important;
  display: flex !important;
  width: 100% !important;
    overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

}


.participants-parinte::-webkit-scrollbar-track {
  border-radius: 5px !important;
  background-color: #fff !important;
  border: 1px solid #76f5bc !important;
}

.participants-parinte::-webkit-scrollbar {
  border-radius: 5px !important;
  background-color: #fff !important;
  height: 5px !important;
}

.participants-parinte::-webkit-scrollbar-thumb {
  border-radius: 5px !important;
  background-color: #093d77 !important;
}

.shareParent {
  display: flex;
  align-items: center;


  button{
    margin-left:5px;
  }

  .copyLink {
    font-size: 28px;
    cursor: pointer;
    margin-left:5px;
  }
}
.joinedPersons{
  overflow-y:auto !important;
  overflow-x: hidden !important;
  padding-bottom: 5px;
}
.joinedPersons::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #76f5bc;
}

.joinedPersons::-webkit-scrollbar {
  border-radius: 5px;
  width: 5px;
  background-color: #fff;
  height: 7px;
}

.joinedPersons::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #093d77;
}

@media screen and (max-device-width : 767px){
  .EventsTitle {
    font-size: 17px;

  }

  .evtPopupPic{
    width:50px;
    height:50px;
  }

  .detailsEventUnderTitle{
    flex-direction: column;
  }

  .detailsEventUnderTitle .EventLabelDesc{
    border:none;
  }

  .detailsEventUnderTitle .EventLabelDesc {
    font-size: 14px;
  }

  .modalPicEv{
    margin-top:0!important;
  }

  .profileAlign .joinButton{
    margin-top:20px;
  }

}
.iWantIn-Sign-btn{
    background-color: #1fb6fe!important;
    width: 100% !important;
    font-size: 17px!important;
    border:none!important;
    border-radius: 50px!important;
    justify-content: center;
    animation: pulse 2s infinite;

    &:hover{
      background-color: #188bc1 !important;
    }
}
.size-sign{
  font-size: 15px !important;
  // border: solid black !important;

  height: 20px !important;
}
.RonSign{
  // border: solid black;


  justify-content: center !important;
}
.indicative-text{
  width: 100% !important;
  border: solid black !important;
}
.one-line-text{
  text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
}

.swalElement{
  display: flex;
  text-align: center;
  justify-content: center;
}
