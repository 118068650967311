@import "../../../Assets/css/variable";


.genderButton {
  height: 40px !important;
  width: 40px !important;
}
.body-Account{
  margin-top: 20px;

  
  .append-image{
    border-top-right-radius: 10px!important;
    border-bottom-right-radius: 10px!important;
  }

  input{
    border-radius: 10px;
    font-size:15px!important;
    height:40px;
    color:#093d77!important;
  }

  .datePick input{
    border-top-right-radius:0;
    border-bottom-right-radius:0;

  }
}
.border {
  border-width: 1px;
  border-style: solid;
  border-color: red;
}

.gender-buttons{

  display: flex !important;

  .feminin-btn{
    border: solid #19b3bb;
    border-radius:  0 3px 3px  0  ;
  }
  .masculin-btn{
    border: solid #19b3bb;
    border-radius: 3px 0 0 3px  ;
  }
  .selected-gender1{
    background-color: #19b3bb!important;
    border: solid transparent;
    border-radius:  3px 0 0 3px ;
  }
  .selected-gender2{
    background-color: #19b3bb!important;
    border: solid transparent;
    border-radius:  0 3px 3px 0 ;
  }
  .gender-img{
    width: 25px ;
    height: 30px;
  }
}
.ChekBox-text{
  display: flex;
  margin-left: 13px;
  height: auto !important;

  .recive-text{
  align-self: center;
  margin-top: 7px;
  }
}
.DeleteBtn-P{


  .DeleteBtn-c{
justify-content: center !important;

  }

}
.delete-text{
  text-align: center;
}
@media (min-width: 576px) {
.offset-sm-1 {
    margin-left: 0 !important; 
}
}
.footer-margin{
  margin:  0 30px 20px 30px  !important;

}
.btn-text-dlt{
  // margin-left: 15px;
  margin: 15px 0 0 15px ;
  display: flex;
}
.delt-txt{
  // margin-left: 10px;
  margin: 5px 0  0   15px;
  align-self: center;
}


.lside{
  border-right:1px solid #eee;
  padding-right:30px!important;
}

.rside{
  padding-left:30px!important;
}

.accTitle{
    font-family: $fontBold;
    margin-bottom:30px;
}

.gBtn{
  background: #20b55c!important;
  border:none!important;
  border-radius: 50px!important;
  padding:7px 30px 7px 30px!important;
  transition: 0.3s;
  
    &:hover{
      background: #0f7237!important;
    }
}