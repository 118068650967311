.greenBtnEvt {
  background-color: #20b55c !important;
  color: white;
  border-radius: 50px;
  font-family: "CircularAirBold";
  font-size: 14px !important;
  cursor: pointer;
  transition: 0.3s;
  border: none !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
  padding: 8px 35px !important;

  &:hover {
    background-color: #1a9e4f !important;
  }
}

.index_big{
  z-index: 2;
}
.index_small{
  z-index: 1;
}
.categoryInputB-deal {
  border-radius: 10px;
  background-color: #2d5683 !important;
  width: 100%;
  justify-content: center !important;
  display: flex;
  align-items: center !important;
  height: 50px;
}
.iconTooltipPrice{
  margin-left: 5px !important;
}

@media only screen and (max-width: 375px) {
  .categoryInputB-deal {

    width: 92%;
    margin-left: 11px !important;
  }
  .categ-label-dial{
    margin-left: 15px;
  }
}
@media only screen and (max-width: 414px) {
  .categoryInputB-deal {
    width: 93%;

    margin-left: 12px !important;
  }
  .categ-label-dial{
    margin-left: 15px;
  }
}
