.B_border {
  border-width: 1px;
  border-style: solid;
  border-color: black;
}


.Bookepeer{
min-height: 550px;

  input{
    margin-bottom: 10px;
    border-radius: 10px;
    color: #fff !important;
    background: #2d5683 !important;
    height: 50px;
  }


  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff !important;
  }
  input::-moz-placeholder { /* Firefox 19+ */
    color: #fff !important;
  }
  input:-ms-input-placeholder { /* IE 10+ */
    color: #fff !important;
  }
  input:-moz-placeholder { /* Firefox 18- */
    color: #fff !important;
  }

.BookepeerContent{
  min-height: 150px;
  background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    padding: 50px;
    border-radius: 50px;
    box-shadow: 10px 10px 22px -3px rgba(0, 0, 0, 0.43);
    margin-top: 20px;
    z-index: 1;
    position: relative;
}


.btnGreen{
  background-color: #20b55c !important;
  color: white;
  border-radius: 50px;
  font-family: "CircularAirBold";
  font-size: 18px !important;
  cursor: pointer;
  transition: 0.3s;
  border: none !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
  padding: 8px 35px !important;

  &:hover{
    background-color: #1a9e4f !important;
  }
}

.YearParent{
    background: #2d5683 !important;
    padding:15px;
    border-radius: 15px;
    color:#fff;
    margin:5px 0px!important;
}

.PriceParent{
  display: flex;
  align-items: center;
  align-self: center;
  background: #15826e !important;
  padding:15px;
  border-radius: 15px;
  color:#fff;
  text-align: center;
  justify-content: center;
}

.detailsHidd{
  transition: 0.3s;
}

.hiddType{
  background: #2d5683 !important;
  padding:10px;
  border-radius: 15px;
  color:#fff;
}

.hiddPrice{
  display: flex;
  align-items: center;
  align-self: center;
  background: #15826e !important;
  padding:10px;
  border-radius: 15px;
  color:#fff;
  text-align: center;
  justify-content: center;
}

.hiddenValues{
  padding:10px;
}

}
.contents_book{
  margin-top: 25px;
}

.monthsSelect {
  background-color:#2d5683 !important;
  border-color: transparent;
  color: #ffffff!important;
  border-radius: 15px !important;
  height: 50px !important;
  padding-left: 20px !important;
  font-size: 15px!important;
}