/*components/bothusers/friends/friends.scss*/
$blueevents: #083d77 !important;
$yellowEvents: #f4d35e !important;
$greenEvents: #00b4b1 !important;
$iconsColor: #0ee6e0 !important;
$purpleColor: #4b0082 !important;
$followColor: magenta !important;
$seeProfileColor: green !important;
$reportColor: red !important;
$fontBold: green !important;

.event {
  background: rgba(255, 255, 255, 0.4);
}

.border {
  border-width: 1px;
  border-style: solid;
  border-color: red;
}

.imageBoxModal {
  width: 100px !important;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
}

.modal {
  background: rgba(255, 255, 255, 0.4);
}

.seeprofile {
  background-color: $seeProfileColor;
  color: white;
  font-size: 9px;
}

.follow {
  background-color: $followColor;
  color: white;
  font-size: 9px;
}

.report {
  background-color: $reportColor;
  color: white;
  font-size: 9px;
}

.categorii {
  color: $followColor;
}
.follow-btn{
  cursor: pointer;
  
}
.event{
  transition: 2s;
}
.searchSpace{
  margin: 10px 0px;
}
.seeAllBtn{
  background-color: #20b55c !important;
    color: white;
    padding: 7px 30px 7px 30px;
    margin: 15px 0px;
    max-width: 150px;
    border-radius: 50px;
    font-family: "CircularAirBold";
    font-size: 14px;
    -webkit-box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
    -moz-box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
    box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
    cursor: pointer;
    transition: 0.3s;

    &:hover{
      background-color: #1b9a4e !important;
    }
}


.friendRow{
  display: flex;
  flex-wrap: wrap;
  margin-top:13px;

  .friendContainer{
    background: #fff;
    border: 2px solid #eee;
    padding-top: 10px;
    border-radius: 5px;
    -webkit-box-shadow: 9px 6px 29px -6px rgba(0,0,0,0.31);
    -moz-box-shadow: 9px 6px 29px -6px rgba(0,0,0,0.31);
    box-shadow: 9px 6px 29px -6px rgba(0,0,0,0.31);
    // margin-right: 13px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: 0.3s;
    width: 135px;
    height: 160px;

        &:hover{
        transform: scale(1.02);
        }

        &:last-child{
          margin-right:0;
        }

        .usrProfilePic{
          width:50px;
          height:50px;
          object-fit: cover;
          border-radius: 50%;
        }

        .imgBoxFriends{
          width:60px;
          height:60px;
          border-radius: 50%;
          object-fit: cover;
          margin:0 auto;
          display: flex;
          justify-content: center;
        }

        .friendName{
          text-align: center;
          margin-top:5px;
          color:#2d5683 !important;
          font-size: 15px;
          height: 23px;
          overflow: hidden;
          border-bottom:1px solid #eee;
          padding-bottom:5px;
        }

        // .viewProf{
        //   text-align: center;
        //   margin-top: 10px;
        //   font-size:14px;
        //   color:#fff;
        //   background: #20b55c;
        //   border-radius: 50px;
        //   padding:5px 0px;

        //   &:hover{
        //     background: #1ea855!important;
        //   }
        // }
  }
}


.imageUserBoxModal {
  width:80px!important;
  max-width: 80px!important;
  height:80px!important;
  border:1px solid #eee;
  border-radius: 50%;
  object-fit: cover;
}



@media screen
and (max-device-width : 1024px)
{
  .friendRow .friendContainer{
    width: 110px;
  }

  .friendRow .friendContainer .viewProf {
    font-size: 12px;
  }

  .seeAllBtn{
    font-size: 15px;
  }
}

@media screen
and (max-device-width : 767px)
{
  .friendRow .friendContainer{
    margin-right:5px!important;
  }
}
.see-blocked-friends{
  justify-content: space-between !important;
}
.usrProfilePic-blocked{
  height: 50px !important;
}
.friendContainer-blocked{
  background: #fff;
  border: 2px solid #eee;
  padding-top: 10px;
  // border-radius: 5px;
  -webkit-box-shadow: 9px 6px 29px -6px rgba(0,0,0,0.31);
  -moz-box-shadow: 9px 6px 29px -6px rgba(0,0,0,0.31);
  box-shadow: 9px 6px 29px -6px rgba(0,0,0,0.31);
  // margin-right: 13px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: 0.3s;
  width: 135px;
  height: 160px;
}
.event-text{
  font-size: 10px !important;
  text-align: center;
}
.noOfEvt-friends {
  // margin:  0  5px 0 5px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  border-radius: 10px;
  color: #157f71;
  border: 1px solid #eee;
  // font-family: $fontBold;
  background-color: rgb(255, 255, 255) !important;
//   cursor:  pointer !important;
// &:hover{
//   background-color: rgb(80, 5, 5) !important;
// }
}
.parent-event-friend{
  z-index: 99 !important;
  width: 100% !important;
  display: flex !important; 
  justify-content: space-between !important;
  .child-event-friend1{
    width: 50% !important;
    margin-top: 1px;
    // border-right: 0.1px solid rgb(131, 131, 131) !important;
  }
  .child-event-friend2{
    width: 50% !important;
    margin-top: 1px;
    border-left: 0.1px solid rgb(230, 230, 230) !important;
  }
}