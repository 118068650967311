/*components/bothusers/friends/friends.scss*/
$blueevents: #083d77 !important;
$yellowEvents: #f4d35e !important;
$greenEvents: #00b4b1 !important;
$iconsColor: #0ee6e0 !important;
$purpleColor: #4b0082 !important;
$followColor: magenta !important;
$seeProfileColor: green !important;
$reportColor: red !important;

.event {
  background: rgba(255, 255, 255, 0.4);
}

.border {
  border-width: 1px;
  border-style: solid;
  border-color: red;
}

.imageBoxModal {
  width: 100px !important;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
}

.modal {
  background: rgba(255, 255, 255, 0.4);
}

.seeprofile {
  background-color: $seeProfileColor;
  color: white;
  font-size: 9px;
}

.follow {
  background-color: $followColor;
  color: white;
  font-size: 9px;
}

.report {
  background-color: $reportColor;
  color: white;
  font-size: 9px;
}

.categorii {
  color: $followColor;
}
.follow-btn{
  cursor: pointer;
  
}
.event{
  transition: 2s;
}
.searchSpace{
  margin: 10px 0px;
}

.nameInput {
  height: 50px !important;
}

.seeAllBtn {
  background-color: #20b55c !important;
    color: white;
    padding: 7px 30px 7px 30px;
    margin: 0px;
    max-width: 150px;
    border-radius: 50px;
    font-family: "CircularAirBold";
    font-size: 14px;
    -webkit-box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
    -moz-box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
    box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
    cursor: pointer;
    transition: 0.3s;

    &:hover{
      background-color: #1b9a4e !important;
    }
}



.see-blocked-friends{
  justify-content: space-between !important;
}
.usrProfilePic-blocked{
  height: 50px !important;
}
.friendContainer-blocked{
  background: #fff;
  border: 2px solid #eee;
  padding: 15px 10px;
  border-radius: 5px;
  -webkit-box-shadow: 9px 6px 29px -6px rgba(0,0,0,0.31);
  -moz-box-shadow: 9px 6px 29px -6px rgba(0,0,0,0.31);
  box-shadow: 9px 6px 29px -6px rgba(0,0,0,0.31);
  margin-right: 13px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: 0.3s;
  width: 135px;
  height: 160px;
}

.zingMapInput .location-search-input {
  width: 100%;
  border: none;
  background-color: #fff !important;
  border-radius: 12px;
  padding-right: 10px;
}
.zingMapInput .borderSearchBar {
  color: #ffffff !important;
  background-color: #fff !important;
  border-radius: 12px !important;
  border: 1px solid #ced4da;
  height: 36.5px !important;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  height: 50px !important;
  ::placeholder {
    color: #2d5683 !important;
    font-family: "CircularAirBold";
    font-size: 15px !important;
  }
}

.zingMapInput .location-search-input {
  color: #2d5683 !important;
  font-family: "CircularAirBold";
  font-size: 15px !important;
  margin: 0px;
  padding: 15px;
  height: 20px;
}
.zingMapInput .suggestion-item {
  color: #2d5683 !important;
  font-family: "CircularAirBold";
  font-size: 15px !important;
}

.zingMapInput .suggestion-item--active {
  color: #2d5683 !important;
  background-color: #e8f0fe !important;
  width: 100%;
}
.zingMapInput .zingMapComponent .autocomplete-dropdown-container {
  position: absolute;
  z-index: 9999;
  display: block;
  border: none;
}

.friendSearchBar {
  display: flex;
  align-items: center;

  .searchButton {
    background-color: #20b55c !important;
    color: white;
    padding: 7px 30px 7px 30px;
    margin: 15px 0px;
    max-width: 150px;
    border-radius: 50px;
    font-family: "CircularAirBold";
    font-size: 14px;
    border:none!important;
    -webkit-box-shadow: 3px 3px 36px 5px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 3px 3px 36px 5px rgba(0, 0, 0, 0.13);
    box-shadow: 3px 3px 36px 5px rgba(0, 0, 0, 0.13);
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: #1b9a4e !important;
    }
  }
}

.peopleArea {
  max-height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-top: 5px !important;
  padding: 10px 15px;
}

.peopleArea::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #fff;
}

.peopleArea::-webkit-scrollbar {
  border-radius: 5px;
  width: 5px;
  background-color: #fff;
}
.peopleArea::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #093d77;
}



.friendRow{
  display: flex;
  flex-wrap: wrap;

  .friendContainer{
    background: #fff;
    border: 2px solid #eee;
    padding-top:  10px;
    border-radius: 5px;
    -webkit-box-shadow: 9px 6px 29px -6px rgba(0,0,0,0.31);
    -moz-box-shadow: 9px 6px 29px -6px rgba(0,0,0,0.31);
    box-shadow: 9px 6px 29px -6px rgba(0,0,0,0.31);
    margin-right: 13px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: 0.3s;
    width: 135px;
    height: 160px;

        &:hover{
        transform: scale(1.02);
        }

        &:last-child{
          margin-right:0;
        }

        .usrProfilePic{
          width:50px;
          height:50px;
          object-fit: cover;
          border-radius: 50%;
        }

        .imgBoxFriends{
          width:60px;
          height:60px;
          border-radius: 50%;
          object-fit: cover;
          margin:0 auto;
          display: flex;
          justify-content: center;
        }

        .friendName{
          text-align: center;
          margin-top:5px;
          color:#2d5683 !important;
          font-size: 15px;
          height: 23px;
          overflow: hidden;
          border-bottom:1px solid #eee;
          padding-bottom:5px;
        }

        .viewProf{
          text-align: center;
          margin-top: 10px;
          font-size:14px;
          color:#fff;
          background: #20b55c;
          border-radius: 50px;
          padding:5px 0px;

          &:hover{
            background: #1ea855!important;
          }
        }
  }
}
.friendSearchBar {
  .categoryInput {
    background-color: white !important;
    height: 50px;
    border-radius: 12px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .categoryInput #combo-box-demo5 {
    border: none;
    border-radius: 15px;
    height: 10px;
    padding-left: 10px;
    background-color: white !important;
    color: #2d5683 !important;
    font-family: "CircularAirBold";
    font-size: 15px !important;
  }

  .categoryInput {
    .MuiAutocomplete-popupIndicator {
      position: relative;
      bottom: 2px;
      border-left: 1px solid #fff;
      border-right: none;
      border-radius: 0;
      background-color: white !important;
    }

    .MuiSvgIcon-root {
      fill: #2d5683 !important;
    }

    .MuiAutocomplete-popupIndicatorOpen {
      border-right: 1px solid #fff;
      border-left: none;
    }

    .MuiAutocomplete-popupIndicator svg {
      font-size: 35px !important;
    }

    .MuiAutocomplete-clearIndicator {
      margin-top: -10px !important;
      margin-right: 5px;
    }

    .categoryInputF {
      border-radius: 10px;
      background-color: white !important;

      width: 100%;
      display: flex;
      align-items: center;
      height: 50px;
      ::placeholder {
        color: #2d5683 !important;
        font-family: "CircularAirBold";
        font-size: 15px !important;
      }
    }
  }
}

.blBackground{
    background: #2d5683b3!important;
    border-radius: 25px;
    border: 2px solid #fff;
    padding: 15px!important;
    -webkit-box-shadow: 6px 6px 14px 3px rgba(0,0,0,0.21);
    -moz-box-shadow: 6px 6px 14px 3px rgba(0,0,0,0.21);
    box-shadow: 6px 6px 14px 3px rgba(0,0,0,0.21);
}

.imageUserBoxModal {
  width:80px!important;
  max-width: 80px!important;
  height:80px!important;
  border:1px solid #eee;
  border-radius: 50%;
  object-fit: cover;
}



@media screen
and (max-device-width : 1024px)
{
  .friendRow .friendContainer{
    width: 110px;
  }

  .friendRow .friendContainer .viewProf {
    font-size: 12px;
  }

  .seeAllBtn{
    font-size: 15px;
  }
}

@media screen
and (max-device-width : 767px)
{
  .friendRow .friendContainer{
    margin-right:5px!important;
  }


  .friendSearchBar .pl-0{
    padding-left:15px!important;
  }


}



