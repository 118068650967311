/*\Components\FirstPage\Footer\Footer.scss*/
@import "../../../Assets/css/variable";
$blueevents: #2d5683 !important;
$bgColor: #09b1ba;
$iconsColor: #00b4b1 !important;
$socialBg: #fff;
$textColor: #fff;

.Footer {
    background: url("../../../Assets/img/footerBg.svg") no-repeat;
    background-size: cover;
    position: relative;
    bottom: 0;
    height: 300px;
    margin-top: 250px;
    z-index: 1 !important;
}

.lgUsr .Footer {
    margin-top: 100px;
}

.logoFooter {
    /*position: absolute;
    bottom: -344px;
    left: 30px;
    cursor: pointer;
    width: 75px;*/
    width: 55px;
    top: 87px;
    left: 92px;
    position: relative;
}

.p-1 {
    cursor: pointer;
}

.linksRight {
    text-overflow: ellipsis; 
overflow: hidden; 
white-space: nowrap;
    position: relative;
    right: 117px;
    display: flex;
    justify-content: flex-end;
}

.rowTitle {
    color: $textColor;
    font-size: 23px;
    font-weight: 300;
    line-height: 60px;
    text-align: center;
}

.footerLinks {
    list-style-type: none;
    right: 85px;
    top: 108px;
}

.footerLinks .Linkitem {
  
    font-size: 22px;
    color: #fff;
    font-family: $fontLight;
    cursor: pointer;
    line-height: 32px;
    transition: 300ms;
    text-align: right;

    &:hover {
        color: #ddd;
    }

    a {
        color: #fff;
    }
}

.download {
    display: flex;
    justify-content: center;
}

// .links{
/*position: absolute;
    bottom: -360px;
    text-align: right;
    float: right;
    right: 150px;
    width: 652px;*/
// }

.footerItm {
    position: absolute;
    top: 115px;
    right: 0;
}

@media screen and (min-device-width: 1921px) and (max-device-width: 3000px) {
    .logoFooter {
        bottom: -352px;
    }

    .Footer {
        margin-top: 865px;
    }
}

@media screen and (max-device-width: 1680px) {
    .Footer {
        margin-top: 195px;
    }
}

@media screen and (max-device-width: 1500px) {
    .Footer {
        margin-top: 190px;
        height: 300px;
    }

    .footerLinks .Linkitem {
        font-size: 20px;
    }

    .logoFooter {
        top: 57px;
        width: 50px;
    }
}

@media screen and (max-device-width: 1280px) {
    .Footer {
        margin-top: 222px;
        height: 259px;
    }
}

@media screen and (max-device-width: 1024px) {
    .footerItm {
        top: 78px;
    }

    .footerLinks .Linkitem {
        font-size: 17px;
        line-height: 29px;
    }

    .links {
        bottom: -218px;
        right: 52px;
        width: 304px;
    }

    .logoFooter {
        top: 42px;
        left: 41px;
        width: 49px;
    }

    .Footer {
        height: 217px;
        margin-top: 50px;
    }
}

@media screen and (max-device-width: 767px) {
    .footerLinks .Linkitem {
        font-size: 14px;
        line-height: 20px;
    }
    .logoFooter {
        top: 15px;
        left: -19px;
        width: 37px;
    }

    .links {
        top: 10px;
        right: -32px;
    }
    .Footer {
        height: 155px;
        margin-top: 93px;
    }

    .lgUsr .Footer {
        bottom: 50px;
    }

    .lgUsr .linksRight {
        right: -22px;
    }

    .linksRight {
        right: 56px;
        top: -4px;
    }

    .footerItm {
        top: 57px;
    }
}

@media screen and (max-device-width: 375px) {
    .h6-email{
        width: 100% !important;
        display: row !important;
        flex-direction: row !important;
    }
    .class1{
        margin-left: 0 !important;
    }
    .Footer {
        height: 141px;
    }

    .logoFooter {
        top: 65px;
        left: -21px;
        width: 27px;
    }
    .footerLinks .Linkitem {
        font-size: 14px;
        line-height: 20px;
    }

    .links {
        bottom: -139px;
    }

    .linksRight {
        top: 30px;
    }

    .footerItm {
        top: 0;
    }
}

.mapContainerFooter {
    width: 100% !important;
    height: 400px;
    padding: 0;
    margin: 0;
    flex-basis: auto !important;
}

.contactInfo {
    margin-bottom: 50px;
    .contactInfoLabel {
        font-family: $fontBold;
        color: #093d77;
    }
    .contactInfoItem {
        margin-bottom: 10px !important;
    }
}

.modalFooter {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

// .contactUsItem {
//     height: 592px;
//     overflow-y: auto;
//     overflow-x: hidden;
//     width: 100%;
//     margin-top: 50px !important;
// }

// .contactUsItem::-webkit-scrollbar-track {
//     border-radius: 5px;
//     background-color: #fff;
// }

// .contactUsItem::-webkit-scrollbar {
//     border-radius: 5px;
//     width: 5px;
//     background-color: #fff;
// }
// .contactUsItem::-webkit-scrollbar-thumb {
//     border-radius: 5px;
//     background-color: #093d77;
// }

.contactUsContainer {
    // border: solid red !important;
    padding: 50px !important;
    input {
        border-radius: 10px;
        color: #fff !important;
        background: #2d5683 !important;
        //   height: 50px;
        font-size: 15px !important;
    }
}

.searchBtn {
    background-color: #20b55c !important;
    color: white;
    height: 50px;
    border-radius: 50px;
    font-family: "CircularAirBold";
    font-size: 17px !important;
    cursor: pointer;
    transition: 0.3s;
    border: none !important;
    text-transform: capitalize !important;
    border-radius: 50px !important;
    padding: 8px 35px !important;

    &:hover {
        background-color: #1a9e4f !important;
    }
}


.searchBtnDisabled {
    background-color: rgb(167, 167, 167) !important;
    color: white;
    height: 50px;
    border-radius: 50px;
    font-family: "CircularAirBold";
    font-size: 17px !important;
    cursor: pointer;
    transition: 0.3s;
    border: none !important;
    text-transform: capitalize !important;
    border-radius: 50px !important;
    padding: 8px 35px !important;

    &:hover {
        background-color: rgb(167, 167, 167) !important;
    }
}
.alertContainer {
    margin-top: 25px;
    width: 437.6px;
}
.drop-down-stl {

    // border: solid 0 !important;
    // max-width: 300px;
    // min-width: 100px;
    width: 100% !important;

    background-color: $blueevents;
}


.autoSearch{
    height: 50px;

    .MuiAutocomplete-inputRoot .MuiAutocomplete-input{
        padding: 2.5px 4px!important;
    }

    .MuiAutocomplete-clearIndicator {
        margin-top: -0 !important;
        margin-right: 5px;
    }

    .MuiInputBase-root{
        height:50px;
    }

    .MuiAutocomplete-endAdornment {
        top: 6px;
    }

    .MuiAutocomplete-popupIndicatorOpen{
        border-right: 1px solid #acc1cb!important;
        border-left:none!important;
    }

    .MuiAutocomplete-popupIndicator{
        position: relative;
        top: -1px;
        border-left: 1px solid #acc1cb;
        border-right:none;
        border-radius: 0;

        svg{
            font-size: 35px!important;

        }
    }

}
.h6-email{
    width: 100% !important;
    display: row !important;
    flex-direction: row !important;
}
.class1{
    margin-left: 70px ;
}
