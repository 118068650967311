@import "../../../Assets/css/variable";
@import 'node_modules/react-modal-video/scss/modal-video.scss';


$arrowColor: #40916f !important;
$titleColor:#0b71d9 !important;
$textColor:#529D98!important;
.modalBodySmall{
    z-index: 100000 !important;
 background-color: transparent !important;   
}


.videoSlider{
    background: url('../../../Assets/img/background_sec2.png') no-repeat;
    background-position: bottom;
    background-size: 100%;
}
.videoSliderLogged{
    background: url('../../../Assets/img/background_sec2.png') no-repeat;
    background-position: bottom;
    background-size: 100%;
}


.modal-video-body {
    max-width: 69vw;
}


.videoSlider .awssld__content{
    background: transparent;
}
.videoSliderLogged .awssld__content{
    background: transparent;
}


.videoSlider .awssld__next,.videoSlider .awssld__prev{
    &:focus{
        outline: none;
    }


}
.videoSliderLogged .awssld__next,.videoSliderLogged .awssld__prev{
    &:focus{
        outline: none;
    }


}

.videoSlider .awssld__controls__arrow-right, .videoSlider .awssld__controls__arrow-left{
    &::before{
        background: $arrowColor;
    }

    &::after{
        background: $arrowColor;
    }
}
.videoSliderLogged .awssld__controls__arrow-right, .videoSliderLogged .awssld__controls__arrow-left{
    &::before{
        background: $arrowColor;
    }

    &::after{
        background: $arrowColor;
    }
}

.videoSlider .awssld__controls{
    display: block!important;

}
.videoSliderLogged .awssld__controls{
    display: block!important;

}

.videoSlider .awssld__prev{
    top: -22px !important;
}
.videoSliderLogged .awssld__prev{
    top: -22px !important;
}


.awssld__controls button .awssld__controls__arrow-left, .awssld__controls button .awssld__controls__arrow-right{
    opacity:0.9!important;
}
.videoSliderLogged .awssld__next {
    top: 409px !important;
}

.videoSliderLogged .awssld__bullets button{
    border-radius: 50%;
    background: $arrowColor;
    transition: 0.1s;
}

.videoSliderLogged  .awssld__wrapper {
    width: 98%;
    margin: 0 auto;
}

.videoSliderLogged .awssld__container{
    width: 98%;
}









.videoSlider .awssld__next {
    top: 409px !important;
}

.videoSlider .awssld__bullets button{
    border-radius: 50%;
    background: $arrowColor;
    transition: 0.1s;
}

.videoSlider  .awssld__wrapper {
    width: 98%;
    margin: 0 auto;
}

.videoSlider .awssld__container{
    width: 98%;
}


  .vidImg{
    width: 100%;
    height: auto;
      border-radius: 50px;
      max-height: 500px;
      max-width: 500px;
  }


.roundBg{
    background: #fff;
    width: 68%;
    margin: 0 auto;
    border-radius: 50px;
    box-shadow: 0px 30px 82.8px 9.2px rgba(29, 33, 33, 0.17);
    height: 640px;
    position: relative;
    left: 0;
    right: 0;
    // top: -100px;
}

.videoSlider .awssld__bullets{
    display: none;
}
.videoSliderLogged .awssld__bullets{
    display: none;
}

.HowitWorkTitle{
    color: $titleColor;
    font-weight: 400;
    font-size: 40px;
    text-align: center;
    padding: 80px 0 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.videoSliderLogged{
    position: relative;
    height: 100vh;

}
.videoSlider{
    position: relative;
    height: 100vh;

}

.sliderTitle{
    font-family: $fontLight;
    font-size: 51px;
    background: linear-gradient(123deg, #155799 17%, #159957 34%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -2px;
  margin-top: 60px;
  line-height: 48px;

} 

.sliderTitleBold{
    font-family: $fontBold;
}
.secTitle{

    margin-top: 5px;
}
.sliderDesc{
    font-size: 28px;
    margin-top: 20px;
    color: #093d77 !important;
    line-height: 34px;
    
}


  .playBtnVid{
      background: url('../../../Assets/img/playVideBtn.png') no-repeat;
      width: 329px;
      height: 327px;
      position: absolute;
      cursor: pointer;
      transition: 0.3s;
      transform: scale(0.9);

      &:hover{
          transform: scale(1);
      }
  }

  .rightVideoImage{
    display: flex;
    justify-content: center;
    align-items: center;
  }


.content {
    position: absolute!important;
    width: 100%;
    left:0;
    right:0;
    top:0;
    bottom:0;
    margin:0 auto;
    padding-top: 60px;

}

.titleIcon{
    margin-right: 10px;
    position: relative;

}




@media screen 
and (min-device-width : 1921px) 
and (max-device-width : 3000px) 
{ 
    .videoSlider .awssld__prev {
        top: -57px !important;
    }

    .videoSlider {
        height: 113vh;
    }
    .videoSliderLogged .awssld__prev {
        top: -57px !important;
    }

    .videoSliderLogged {
        height: 113vh;
    }

}


@media screen 
and (max-device-width : 1920px) 
{ 

    .HowitWorkTitle {
        padding: 80px 0 100px;
    }

    .videoSlider {
        height: 1088px;
        top: 30px;
    }
    .videoSliderLogged {
        height: 1088px;
        top: 30px;
    }

}

@media screen 
and (max-device-width : 1600px) 
{ 
    .videoSlider {
        height: 869px;
    }
    .videoSliderLogged {
        height: 869px;
    }

    .sliderDesc {
        font-size: 24px;
        line-height: 26px;
    }

    .roundBg {
        width: 80%;
        height: 595px;
    }

}

@media screen 
and (max-device-width : 1440px) 
{ 
    .videoSlider {
        background-position: top;
        height: 100vh;
    }
        .vidSize{
            width: 700px!important;
        }

        .vidImg {
            width: 350px;
            height: 350px;
        }

        .videoSlider {
            height: 90vh;
            top:0;
        }

        .videoSlider .awssld__prev {
            top: -29px !important;
        }

        .videoSliderLogged {
            background-position: top;
            height: 100vh;
            top:0;
        }

        .videoSliderLogged .awssld__prev {
            top: -29px !important;
        }
        .roundBg {
            height: 513px;
            width: 87%;
        }

        .playBtnVid{
            zoom:0.7;
        }
        

        .sliderDesc {
            font-size: 22px;
            line-height: 27px;
        }

        .sliderTitle {
            font-size: 45px;
        }

        .videoSlider .awssld__bullets {
            bottom: 188px !important;
        }
        .videoSliderLogged .awssld__bullets {
            top: 270px !important;
            display: flex !important; 
        }

        .container {
            width: 1400px !important;
        }

        .videoSlider .awssld__next {
            top: 359px !important;
        }
        .videoSliderLogged .awssld__next {
            top: 359px !important;
        }
}


@media screen 
and (max-device-width : 1280px) 
{


    .sliderTitle {
        font-size: 45px;
        line-height: 37px;
        margin-top: 45px;
        
    }

    .container {
        width: 1200px !important;
    }
    
    
        .vidSize{
            width: 650px!important;
        }
        .videoSliderLogged {
            height: 100vh;
        }

        .videoSliderLogged .awssld__bullets {
            bottom: 238px !important;
        }

   

        .videoSliderLogged .awssld__next {
            top: 374px  !important;
        }



    

        .videoSlider {
            background-position: top;
            height: 100vh;
        }

        .videoSlider .awssld__bullets {
            bottom: 238px !important;
        }

   

        .videoSlider .awssld__next {
            top: 374px  !important;
        }

        .roundBg{
            height: 500px;
            width: 81%;
        }

        .playBtnVid{
            zoom:0.5;
        }

        .sliderDesc {
            font-size: 22px;
            line-height: 29px;
        }

        .vidImg {
            width: 350px;
            height: 350px;
        }


        .HowitWorkTitle {
            padding: 60px 0 80px;
        }
}


@media screen 
and (max-device-width : 1024px) 
{ 
    .container {
        max-width: 100%!important;
        width:100%!important;
    }

    .vidImg {
        width: 300px;
        height: 300px;
    }

    .playBtnVid{
        zoom: 0.3;
    }

    .sliderTitle {
        font-size: 36px;
        line-height: 36px;
        margin-left: 18px;
        margin-top: 28px;
        
    }

    .roundBg {
        width: 97%;
    }


    .vidImg {
        width: 200px;
        height: 200px;
    }

    .videoSlider{
        background-position: top;
        height: 650px;
        top: -70px;
    }
    .videoSliderLogged{
        background-position: top;
        height: 650px;
        top: -70px;
    }
    
    .rightVideoImage{
        margin-top:30px;
    }



    .sliderDesc {
        font-size: 18px;
        line-height: 23px;
        margin-top: 10px;
        margin-left: 19px;
    }

    .roundBg {
        height: auto;
    }

    .videoSlider .awssld__next {
        top: 290px !important;
    }

    
    .videoSlider .awssld__prev {
        top: 10px !important;
    }
    .videoSliderLogged .awssld__next {
        top: 290px !important;
    }

    
    .videoSliderLogged .awssld__prev {
        top: 10px !important;
    }

    .sliderTitle {
        margin-top: 47px;
        
    }



  

}



@media screen 
and (max-device-width : 1024px) and (orientation : landscape){
    .roundBg {
        height: 360px;
        width: 79%;
    }

    .videoSlider{
        top: 70px;
    }

    .videoSlider .awssld__next {
        top: 242px !important;
    }
    .videoSliderLogged{
        top: 70px;
    }

    .videoSliderLogged .awssld__next {
        top: 242px !important;
    }

    .sliderTitle {
        margin-top: 23px;
    }




}


@media screen and (max-device-width: 767px) {
    .videoSliderLogged .awssld__wrapper {
        height: 300px !important;
    }
.videoSliderLogged .awssld__box{
    
    height: 300px !important;
}
  .videoSlider .awssld__bullets {
    display: flex !important;
    bottom: -25px !important;

  }
  .videoSlider .awssld__bullets--active {
    width: 14px;
    height: 14px;
  }
  .videoSliderLogged .awssld__bullets--active {
    width: 14px;
    height: 14px;
  }
  .videoSlider .awssld__bullets button {
    width: 10px;
    height: 10px;
  }
  .videoSliderLogged .awssld__bullets button {
    width: 10px;
    height: 10px;
  }
    .modal-video{
        z-index:9999999999!important;
    }
  

    .roundBg {
        width: 92%!important;
      /*   overflow: hidden; */
        position: inherit;
        z-index: 1;
        height: 320px;
        padding-top: 27px;
        top: -160px;

    }


    .sliderTitle {
        font-size: 28px;
        line-height: 27px;
    }

    .sliderDesc {
        font-size: 15px;
        line-height: 17px;
        margin-top: 7px;
    }

    .sliderTitle {
        margin-top: 0px;
    }

    .videoSlider {
        height: 200px;
        top: 20px;
    }
    .videoSliderLogged {
        height: 200px;
        top: 200px;
    }

    .vidImg {
        width: 80px;
        height: 70px;
        border-radius: 16px;
    }

    .playBtnVid {
        zoom: 0.2;
    }

    .content{
        padding-top: 0!important;
    }

    .rightVideoImage {
        margin-top: 10px;
    }

    .modal-video-body {
        max-width: 80vw!important;
    }

    .content{
        width:100%;
    }

}


@media screen 
and (max-device-width : 812px) and (orientation : landscape){
    .sliderTitle {
        margin-top: 30px;
    }

    .rightVideoImage {
        margin-top: 16px;
    }

    .videoSlider {
        height: 136vh;
    }
    .videoSliderLogged {
        height: 136vh;
    }
}

@media screen 
and (max-device-width : 737px) and (orientation : landscape){
 
    .sliderTitle {
        margin-top: 93px;
    }

    .rightVideoImage {
        margin-top: 66px;
    }

    .videoSlider {
        height: 88vh;
    }
    .videoSliderLogged {
        height: 88vh;
    }

}


@media screen and (max-device-width: 375px) {
  .videoSlider .awssld__bullets {
    display: flex !important;
    bottom: -40px !important;
  }
  .rightVideoImage {
    margin-top: 5px;
  }

    .vidImg {
        width: 80px;
        height: 70px;
        border-radius: 15px;
    }

    .sliderTitle {
        font-size: 22px;
        line-height: 20px;
    }

    .sliderDesc {
        font-size: 12px;
        width: 100%;
        height: auto;
        overflow: hidden;
    }

    .roundBg {
        top: -160px;
    }

}


