.autocomplete-dropdown-promotions div {
  padding: 7px 5px 7px 15px;
  font-size: 14px;
}
.locations_promotions_voucher {
  width: 100%;
  height: 50px;
  border: none;
  background-color: #2d5683 !important;
  border-radius: 8px;
  padding-right: 10px;
  padding-left: 10px;
  color:white!important;
}

.suggestion-item {
  color: #000000 !important;
}

.suggestion-item--active {
  color: #ffffff !important;
  background-color: #2d5683 !important;
}


input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff !important;
}
input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff !important;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff !important;
}
input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff !important;
}
