.addCardsInput{
    margin: 5px 0px;
}
.addbtnspaces{
    margin: 5px;
}
.CkJFS form, .CkJFS div.form{
    max-width: 100%!important;
}
.cardHalfWidth{
    width:50%!important;
}
.inputID::placeholder{
    color: rgb(0, 0, 0)  !important;
    }