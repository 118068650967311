$blueevents: #2d5683 !important;
$yellowEvents: #f4d35e !important;
$greenEvents: #00b4b1 !important;
$iconsColor: #0ee6e0 !important;
$purpleColor: #4b0082 !important;
$followColor: magenta !important;
$seeProfileColor: green !important;
$reportColor: red !important;
$details: #3ab54a !important;
$radius: 15px;

.searchContainerBus {
  background-color: #39b6fe;
  height: 50px !important;
  width: 50px !important;
  max-width: 50px !important;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #0da5fd !important;
  }
}

.search-bar-div {
  height: auto !important;
  justify-content: space-around !important;
  border-radius: 17px !important;
  // max-height: 66px;
  // width: 95% !important;

  .noMargin.row {

    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .calendarSearch {
    width: 100% !important;
    background-color: #fff;
    border-radius: 10px;
  }

  .SearchWordInput {
    background: $blueevents;
    color: #ffffff !important;
    border-radius: 15px !important;
    height: 50px !important;
  }

  .SearchWordInput-small {
    background: $blueevents;
    border-radius: 10px !important;
  }

  #inptID::placeholder {
    color: #ffffff !important;
  }

  input {
    color: #fff;
    padding-left: 20px;
  }

  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff !important;
  }

  input::-moz-placeholder { /* Firefox 19+ */
    color: #fff !important;
  }

  input:-ms-input-placeholder { /* IE 10+ */
    color: #fff !important;
  }

  input:-moz-placeholder { /* Firefox 18- */
    color: #fff !important;
  }
}

.search-bar-div-voucher {
  height: auto !important;
  justify-content: space-around !important;
  border-radius: 17px !important;
  //max-height: 66px;
  // width: 95% !important;

  input {
    color: #fff;
    padding-left: 20px;
  }

  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #fff !important;
  }

  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff !important;
  }

  input:-ms-input-placeholder {
    /* IE 10+ */
    color: #fff !important;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #fff !important;
  }
}

.bg-white {
  background-color: #fff !important;
  -webkit-box-shadow: 2px 3px 13px -1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 2px 3px 13px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 3px 13px -1px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 3;
}


.autoSearch {
  height: 50px;

  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    padding: 2.5px 4px !important;
  }

  .MuiAutocomplete-clearIndicator {
    margin-top: -0 !important;
    margin-right: 5px;
  }

  .MuiInputBase-root {
    height: 50px;
  }

  .MuiAutocomplete-endAdornment {
    top: 6px;
  }

  .MuiAutocomplete-popupIndicatorOpen {
    border-right: 1px solid #acc1cb !important;
    border-left: none !important;
  }

  .MuiAutocomplete-popupIndicator {
    position: relative;
    top: -1px;
    border-left: 1px solid #acc1cb;
    border-right: none;
    border-radius: 0;

    svg {
      font-size: 35px !important;

    }
  }

}


.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiInputBase-input {
  color: #fff !important;
  opacity: 1 !important;
}


.MuiInputBase-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff !important;
  opacity: 1 !important;
}

.MuiInputBase-input::-moz-placeholder { /* Firefox 19+ */
  color: #fff !important;
  opacity: 1 !important;
}

.MuiInputBase-input:-ms-input-placeholder { /* IE 10+ */
  color: #fff !important;
  opacity: 1 !important;
}

.MuiInputBase-input:-moz-placeholder { /* Firefox 18- */
  color: #fff !important;
  opacity: 1 !important;
}

.MuiSvgIcon-root {
  fill: white !important;
}

.div-search-mob {
  display: flex !important;
  // width: 300px !important;
  border-radius: 20px !important;
}

.divv-flex {
  display: flex !important;
  // width: 300px !important;
  border-radius: 20px !important;
  padding-right: 1px !important;
}

.divv-flex-mob {
  height: 50px !important;
  display: flex !important;
  // width: 300px !important;
  border-radius: 20px !important;
  padding-right: 1px !important;
}

.dropdown-stl {
  position: none !important;
  background-color: #083d77 !important;
  border-radius: 0 15px 15px 0 !important;
}

.input-group-append .btn,
.input-group-prepend .btn {
  position: unset !important;
  z-index: 2 !important;
  height: 40px !important;
  padding-right: 40px;
  padding-bottom: 10px;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
}

.input-stll {
  max-width: 100% !important;
  min-width: 100% !important;
  width: 100% !important;
  background-color: #083d77 !important;
  border-radius: 15px 0 0 15px !important;
  border: 0 !important;
  // padding-right: 10px !important;
  height: 60px !important;
}

.margin-left_div {
  margin: 0 !important;
  display: flex !important;
}

.margin-left_divv {
  justify-content: space-between !important;
}

.datePickerSearch-other {
  min-width: 145px;
  width: 100% !important;
}

.datePickerSearch {
  margin-right: 10px !important;
  min-width: 145px;
  width: 100% !important;
}

.datePickerSearch {
  .DatePicker {
    width: 100% !important;
  }
}

.borderSearchBar-small {
  // margin-right: 10px !important;
  height: 50px !important;
  border-radius: 10px !important;
  // min-width: 380px !important;
  // max-width: 380px !important;
  // width: auto !important;
  margin-left: 0 !important;
}

.borderSearchBar {
  margin-right: 10px !important;
  height: 50px !important;
  border-radius: 15px !important;
  // min-width: 380px !important;
  // max-width: 380px !important;
  // width: auto !important;
  margin-left: 0 !important;
}

.div-search {
  align-self: center !important;
}
.div-search-mob{
  align-self: center !important;
  
}
.mrg-lft {
margin-left: 0 !important;
}
.calendar.calendar {
  border-radius: $radius;
  color: #ffff !important;
  background-color: $blueevents;
  height: 50px;
  text-align: left;
  border: none;
  //display: block !important;
  width: 100% !important;
}

.drop-down-stl {

  // border: solid 0 !important;
  // max-width: 300px;
  // min-width: 100px;
  width: 100% !important;

  background-color: $blueevents;
}

.PrivateNotchedOutline-root-83 {
  border-style: none !important;
  border-width: none !important;
}

.jss83 {
  border-style: none !important;
  border-width: none !important;
}


.lgUsr .bRadius > div {
  border-radius: 15px;
  margin-right: 5px !important;
}
@media screen and (max-device-width: 1280px) {

    .drop-down-stl{
      width:90% !important;

    }

  .datePickerSearch-other{
    min-width: 145px !important;
  }
  .datePickerSearch .DatePicker{
    width: 90% !important;
  }
  .form-control{
    width: 90% !important;
  }

}
@media screen and (max-device-width : 1920px)
{  
  .datePickerSearch-other {
    min-width: 170px;
    width: 100% !important;
  }
  .datePickerSearch {
    margin-right: 10px !important;
    min-width: 170px;
    width: 100% !important;
  }

}

@media screen and (max-device-width: 1024px) {
  .datePickerSearch-other {
    min-width: 125px;
    width: 100% !important;
  }
  .datePickerSearch {
    margin-right: 10px !important;
    min-width: 125px;
    width: 100% !important;
  }
}

@media screen and (max-device-width: 767px) {
  .searchContainerBus {
    height: 50px !important;
    width: 50px !important;
    max-width: 50px !important;
    img {
      height: 17px !important;
    }
  }
  .search-bar-div input {
    padding-left: 10px;
    // color: black !important;
    // background-color: blue;
    border-radius: 20px;
    background-color: #2d5683 !important;
    border: none !important;
    text-align: left !important;
    z-index: 5000 !important;
  }

  .search-bar-div-voucher input {
    padding-left: 10px;
    // color: black !important;
    // background-color: blue;
    //border-radius: 20px;
    background-color: #2d5683 !important;
    border: none !important;
    text-align: left !important;
    z-index: 5000 !important;
  }

  .MuiAutocomplete-endAdornment {
    right: 3px !important;
    top: 1px !important;
  }

  .autoSearch {
    height: 39px !important;
    border-radius: 9px !important;

    .MuiInputBase-root {
      height: 40px !important;
    }

    .MuiInputBase-input {
      font-size: 14px !important;
    }
  }


  .mobileSearch {

    width: 92%;
    margin: 0 auto;
    background: #fff;
    height: 38px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50px;
    z-index: 1;
  }


  .mobSearchBar {
    margin-top: 10px;
  }
  .div-search-mob{
    align-self: center!important;
    // margin-top: 44px !important;
    margin-bottom: 10px !important;
  }
  .borderSearchBar-small {
    height: 39px !important;
    font-size: 14px !important;
    border-radius: $radius;
    background-color: #fff;
  }
  .borderSearchBar {
    height: 39px !important;
    border-radius: 9px !important;
    font-size: 14px !important;
  }

  .calendar.calendar {
    // border-radius: 9px;
    // height: 39px;
    // font-size: 14px !important;
    // border: none;
    border-radius: $radius !important;
    background-color: #2d5683 !important;
    height: none !important;
    text-align: left !important;
    border: none !important;
    //display: block !important;
    width: 100% !important;
    z-index: 9999 !important;
    // color: black !important;
  }

  .searchBarParent {
    justify-content: center;
    flex-direction: column;

    .col-12 {
      flex: 0 !important;
      margin-bottom: 10px;
    }
  }

}

.searchEventMobile {
  z-index: 102;
}

.searchEventMobile .location-search-input {
  padding-top: 5px !important;
  margin-top: 0px !important;
}

.search-bar-div .location-search-input {
  width: 100%;
  border: none;
  background-color: #2d5683 !important;
  border-radius: 15px;
  margin-top: 10px;
  padding-right: 10px;
}

.search-bar-div .location-search-input {
  color: #ffffff !important;
}

.search-bar-div .suggestion-item {
  color: #000000 !important;
}

.search-bar-div .suggestion-item--active {
  color: #ffffff !important;
  background-color: #2d5683 !important;
}

.search-bar-div-voucher .location-search-input {
  width: 100%;
  border: none;
  background-color: #2d5683 !important;
  border-radius: 15px;
  margin-top: 10px;
  padding-right: 10px;
}

.search-bar-div-voucher .location-search-input {
  color: #ffffff !important;
}

.search-bar-div-voucher .suggestion-item {
  color: #000000 !important;
}

.search-bar-div-voucher .suggestion-item--active {
  color: #ffffff !important;
  background-color: #2d5683 !important;
}

@media screen and (max-device-width: 1280px) {
  .Calendar {
    font-size: 8px;
    padding-top: 1.1em;
    width: 30em;
    max-width: 85vw;
    height: 32em;
    min-height: 32em;

  }
}
