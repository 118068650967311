@import "../../../Assets/css/variable";


$titleColor :#09b4b8;
$descColor:#0a91c9;
$iconColor:#7b4b94;
$bigTitleColor:#0b71d9;



.AboutUs{
  background: url('../../../Assets/img/bgabout.svg') no-repeat;
  background-size: cover;
  position: relative;
  height: 1899px;
  top: -215px;
  width: 100%;
  z-index: 0;
}
.AboutUsLogged{
  background: url('../../../Assets/img/bgabout.svg') no-repeat;
  background-size: cover;
  position: relative;
  height: 1899px;
  top: -215px;
  width: 100%;
  z-index: 0;
}
 
.aboutUsContent{
  width: 70%;
  margin: 0 auto;
  padding-top: 20%;
}

.AboutUsTitle{
    font-family: $fontLight;
    font-size: 50px;
    text-align: center;
    padding: 70px 0 50px;
    width: 100%;
    position: relative;
    top: 0;
    color:#fff;

    b{
      font-family: $fontBold;
    }
}




.AboutUs .titleIcon {
  top: -6px;
}

.itmTitle{
  color:#fff;
  font-size:35px;
  font-family: $fontBold;
  margin-top:30px;
}

.itmTitle::before{
  content:url('../../../Assets/img/aboutIcon.png');
  width:38px;
  height:38px;
  margin-right:15px;
  zoom:0.7;
}

.stepsTexts{
  text-align: left;
}

.itmDesc{
color:#fff;
font-size: 22px;
padding-top:20px;
}
 

.priceIcon{
  font-size:60px;
  color:$iconColor;
  background:#00908e;
  border-radius:50%;
  width: 120px;
  height:120px;
  display: flex;
  justify-content: center;
  align-items: center;

margin: 0 auto;
    box-shadow: 0 1px 3px 0 rgba(89, 98, 115, 0.12);
    -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
    transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
    transition: transform 200ms ease, box-shadow 200ms ease;
    transition: transform 200ms ease, box-shadow 200ms ease, -webkit-transform 200ms ease;
cursor: pointer;

  &:hover{
    box-shadow: 0 6px 12px 0 rgba(89, 98, 115, 0.12);
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);

  }
}

@media screen 
and (min-device-width : 1920px) 
and (max-device-width : 3000px) 
{ 
  .aboutUsContent{
    width: 70%;
    margin: 0 auto;
    padding-top: 10% !important;
  }
}
@media screen 
and (min-device-width : 1921px) 
and (max-device-width : 3000px) 
{ 

  .AboutUs{
    height: 2550px;
    top: -250px;
  }

}

@media screen 
and (max-device-width : 1680px) 
{ 

  .AboutUs {
    height: 1645px;
    top: -345px;

}

  .AboutUsTitle {
    padding: 70px 0 50px;
  }


    .itmTitle {
      font-size: 33px;
    }


.aboutUsContent {
    padding-top: 19%;
}

}


@media screen 
and (max-device-width : 1440px) 
{ 
  .aboutUsContent{
    width: 70%;
    margin: 0 auto;
  }
  .AboutUsTitle {
    padding: 50px 0 49px;
    font-size: 57px;
}

.itmTitle {
  font-size: 30px!important;
}

.itmDesc {
  font-size: 19px;
}


.AboutUs {
  height: 1500px;
}

.itmTitle::before{
  zoom:0.6;
}

}




@media screen 
and (max-device-width : 1280px) {
  .AboutUs {
    height: 1318px;
  }

}


@media screen 
and (max-device-width : 1024px) 
{ 

  .AboutUsTitle {
    font-size: 40px;
    padding: 165px 0 16px;

  }

  .AboutUs {
    height: 1295px;
}

.itmTitle::before {
  position: relative;
  top: 9px;
}
  .itmTitle {
    font-size: 30px!important;
  }

  .itmDesc {
    font-size: 22px;
  }

}



@media screen 
and (max-device-width : 1024px) and (orientation : landscape){

  .itmTitle {
    font-size: 26px !important;
  }

  .itmDesc {
    font-size: 16px;
}

}



@media screen 
and (max-device-width : 767px) 
{ 
  .AboutUsTitle {
    font-size: 25px;
  }


  .itmDesc {
    font-size: 15px;
  }

  .itmTitle {
    font-size: 22px !important;
  }

  .itmTitle::before {
    zoom: .5;
    top: 2px;
  }

  .AboutUsTitle {
    padding: 150px 0 0px;
  }

  .AboutUs {
    height: 917px;
    z-index: -1;
    top: -232px;
  }

}
@media screen and (max-device-width : 414px) {

  .AboutUsLogged {
    height: 917px !important;
    z-index: 1;
    top: 0 !important;
    width: 100% !important;
 
  }

  .MuiDialogContent-root {
    padding: 0 !important;
  }
  .aboutUsContent{
    width: 100% !important;
  }
}




@media screen and (max-device-width : 375px) {

  .AboutUsLogged {
    height: 917px !important;
    z-index: 1;
    top: 0 !important;
    width: 100% !important;
  
  }
  .aboutUsContent{
    width: 100% !important;
  }
.MuiDialogContent-root {
  padding: 0 !important;
}

}