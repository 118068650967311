@import "../../../Assets/css/variable";

$titleColor:#083d77!important;

.ourPartnersTitle{
    
    font-size: 60px;
    text-align: center;
    padding: 80px 0 50px;
    width: 100%;
    position: relative;
    top: 0;
    background: linear-gradient(115deg, #155799 47%, #159957 59%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -2px;

}

.ourPartnersTitle b{
    font-family: $fontBold;
}


.ourPartners{
    width: 70%;
    margin: 0 auto;
    box-shadow: 0px 30px 82.8px 9.2px rgba(29, 33, 33, 0.47);
    border-radius: 50px;
    margin-top: -68%;
    position: relative;
    z-index: 9;
    background: #fff;
    height: 645px;
}
  

.ourParent{
    overflow-y: auto;
    max-height: 400px !important;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}


.partners{
    // border: solid black;
    

    // width: 200px;
    // height: 100px;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    width: 20%;
    height: 140px;
    margin: 5px !important;
    // padding: 80px ;
    background: transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    // box-shadow: 0px 43px 118.59px 58.41px rgba(29, 33, 33, 0.17);
 
      
    img{
        max-width: 100%;
        max-height:120px;
        object-fit: cover;
    }
}



@media screen 
and (min-device-width : 1921px) 
and (max-device-width : 3000px) 
{ 
    .ourPartners{
        margin-top: -77%;
    }

}



@media screen 
and (max-device-width : 1680px) 
{ 
    .ourPartners {
        margin-top: -65%;
    }

}

@media screen 
and (max-device-width : 1440px) 
{ 
    .ourPartners {
        margin-top: -71%;
        height:auto;
        padding-bottom: 30px;
        margin-bottom: 40px;
    }

    .ourPartnersTitle {
        font-size: 67px;
    }

 
    .ourParent{
        overflow-y: auto;
        max-height: 300px !important;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
    }
  
}

@media screen 
and (max-device-width : 1280px) 
{ 
 
    .ourPartners {
        margin-top: -72%;
        height:auto;
        
        padding-bottom: 30px;
    }


    .ourParent{
        overflow-y: auto;
        max-height: 300px !important;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
    }

}




@media screen 
and (max-device-width : 1024px) 
{ 
    .ourPartnersTitle {
        font-size: 64px;
    }

    .ourPartners {
        width: 97%;
        padding-bottom: 30px;
        margin-top:-75%;
    }

    .partners{
        width: 45%;
        margin:10px;
    }

}

@media screen 
and (max-device-width : 1024px) and (orientation : landscape){

    .partners img {
        max-height: 82px;
    }

}




@media screen 
and (max-device-width : 767px){
    .ourPartnersTitle {
        font-size: 35px;
       padding: 18px 0 22px;
    }

    

    .ourParent{
        flex-wrap: wrap;
    }

    .partners {
        margin-bottom: 0;
        width: 290px;
        padding: 10px;
        height: 60px;
    }

    .partners img {

        max-height: 60px;
    }

    .ourPartners {
        margin-top: -80%;
        width: 90%;
        height: 360px;
    }

  

    

}



@media screen 
and (max-device-width : 736px) and (orientation : landscape){
    // .ourParent {
    //     flex-direction: row;
    // }

    .ourPartners {
        margin-top: -50%;
    }


}

@media screen and (max-device-width: 414px) {
  .ourParent {
    flex-direction: row;
    max-height: 250px !important;
    height: 250px !important;
  }
  .partners {
    width: 93px;
  }

}

@media screen and (max-device-width: 375px) {
  .ourParent {
    flex-direction: row;
    max-height: 250px !important;
    height: 250px !important;
  }
  .partners {
    width: 93px;
  }

}

