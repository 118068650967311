.container-categ {
  min-height: auto !important;
  height: auto !important;
  width: 100% !important;
}

.maxWidth-body {
  // position: absolute;
  margin: 0 !important;
  top: 110px;
  z-index: 5;

  width: -webkit-fill-available;

  align-items: center !important;
  align-self: start;
}
.inputID::placeholder{
  color: #d6d6d6 !important;
  opacity: 10 !important;
}

.done-categ {
  cursor: pointer;
  margin: 0;
  transition: 0.3s;
  border: 1px solid rgb(207, 207, 207) !important;
  border-radius: 0 0.2rem 0.2rem 0;
  border-left: 0 !important;
  padding: 5px;
  font-weight: 700 !important;
  background-color: rgb(241, 241, 241);

}

.done-categ:hover {
  background-color: rgb(241, 241, 241);
  transition: 0.3s;
  border: 1px solid rgb(167, 167, 167);
  border-left: 0 !important;
  color: rgb(73, 164, 255) !important;

}

.addBtn {
  background-color: #20b55c !important;
  color: white;
  border-radius: 50px !important;
  font-size: 15px;
  padding: 5px 25px 5px 25px !important;
  text-align: center;
  border: none !important;
  margin-right: 8px;
}

.categ-x {
  display: flex;
  // border-bottom: 1px solid #155799;
  border-radius: 0px;
  padding: 0 10px 0 10px;
  align-items: center;
  height: 30px !important;
  cursor: pointer;

  .categ-input {
    font-weight: 600 !important;
    padding: 0 5px 0 5px;
    // width: 100% !important;
    justify-content: flex-start;
    text-align: left;
    align-self: center;
    align-content: flex-start;

  }

  .categ-input:hover {
    padding: 0 5px 0 5px;
    font-weight: 600 !important;
    // background-color: rgb(103, 103, 255);

    // width: 100% !important;
    color: rgb(0, 105, 204);
  }
}


@media (min-width: 768px) {
  .offset-md-3 {
    margin-left: -25px !important;
  }
}

.type-input-change-name {
  display: flex;

}


.subcateg-input {
  border-radius: 0.2rem 0 0 0.2rem !important;
  border-right: 0 !important;


  font-weight: 600 !important;
  padding: 0 5px 0 5px;
  // width: 100% !important;
  justify-content: flex-start;
  text-align: left;
  align-self: center;
  align-content: flex-start;

}

.subcateg-input:hover {
  padding: 0 5px 0 5px;
  font-weight: 600 !important;
  // background-color: rgb(103, 103, 255);

  // width: 100% !important;
  color: rgb(0, 105, 204);
}

.parent-categ-save-btn {
  justify-content: center !important;

}

.select-input {
  padding-top: 10px !important;
  height: auto !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1rem;
  // justify-content: space-around;
  align-self: center;
  align-items: center;
}

.select-inputt {
  height: auto !important;
  display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  // justify-content: center;

}

.not-selected-item {
  align-self: center !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 4px;
  font-size: 12px !important;
  width: auto !important;
  border-radius: 50px !important;
  color: #2d5683 !important;
  background-color: rgb(255, 255, 255) !important;
  transition: 0.3s;
  border: 1px solid #2d5683 !important;

  &:hover {
    background-color: #2d5683 !important;
    color: #fff !important;
  }
}

.parent-selected-items {
  align-self: start !important;
  display: flex !important;
  justify-content: center;
  // align-items: center;
  margin: 3px;
  font-size: 12px !important;
  max-width: 220px !important;
  border-radius: 8px !important;
  color: gray !important;
  background-color: rgb(255, 255, 255) !important;
  height: auto !important;

}

.selected-item {
  font-size: 12px !important;
  width: auto !important;
  align-self: center !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 4px;
  border-radius: 50px !important;
  color: white;
  background-color: #2d5683 !important;
  border: 1px solid #2d5683 !important;
}

.adder-class {
  text-align: center;
  display: block;
  margin-bottom: 20px;
  margin-top: 30px !important;
}

.adderText {
  width: auto !important;
  border-radius: 15px !important;
  background: #2d5683 !important;
  margin-right: 10px;
  border: none !important;
  color: #fff !important;

}

.selected-category-color {
  color: #0a91c9;
}

.button-skip {
  margin-top: 10px;
  align-self: center;
}

.categ-select-parent {
  height: auto !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 150px;
  align-items: flex-start !important;
  align-content: center !important;

}

.choosen-categ-subcateg {
  max-width: 300px;
  // border-top: 2px solid #155799;
  // border-bottom: 2px solid #159957;
  // background-image: linear-gradient(#155799, #159957),
  // linear-gradient(#155799, #159957);
  // background-size: 2px 100%;
  // background-position: 0 0, 100% 0;
  // background-repeat: no-repeat;

  // border-radius: 5px;
  border: solid 1px #6c757d;
  border-radius: 15px;
}

.main-categ {
  width: 100% !important;
  text-align: left !important;
  margin: 0 !important;
  color: rgb(68, 68, 68);
  font-weight: bold;
  cursor: pointer;

}

.sub-categ {
  font-size: 15px;
  width: 100% !important;
  text-align: center !important;
  margin: 0 !important;


  .sub-category-X {
    color: rgb(68, 68, 68);
    border: 2px solid transparent;
    width: 20px !important;
    border-radius: 10px;
    font-weight: 600 !important;
    padding: 0 5px 0 5px;
    // width: 100% !important;
    justify-content: flex-start;
    text-align: left;
    align-self: center;
    align-content: flex-start;


  }

  .sub-category-X:hover {
    color: red;
    border: 2px solid black;
    border-radius: 10px;
    transition: 2s;
    cursor: pointer;
  }
}

.underline-center-parent {
  align-items: center;

  display: flex;
  width: 100% !important;

  .underline-center-chld-1 {
    padding: 1rem;
    position: relative;
    background: linear-gradient(to right, #159957, #155799);
    padding: 2px;
    width: 100%;
  }

  .underline-center-chld-2 {
    padding: 1rem;
    position: relative;
    background: linear-gradient(to right, #155799, #159957);
    padding: 2px;

    width: 100%;

  }

  .Text-underline {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;


    font-size: 30px;
    background: -webkit-linear-gradient(#155799, #159957);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  }
}

.header-buttons {
  width: 100% !important;
  height: 40px !important;
  margin-top: -5px;
}

.Main-categ-Button1 {
  color: rgb(255, 255, 255) !important;
  background-color: #159957 !important;
  width: 33.3%;
  border-radius: 0 !important;
}

.Main-categ-Button2 {
  color: rgb(255, 255, 255) !important;
  background-color: #2d5683 !important;
  width: 33.4%;
  border-radius: 0 !important;
}


@media (max-width: 768px) {
  .parent-selected-items {
    max-width: 175px !important;
  }
}

