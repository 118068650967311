.muliplyContainer {
  .center {
    display: flex;
    align-items: center;
  }
  .Checkbox{
    top:0px!important;
  }

  .DatePicker input {
    width: 100%!important;
  }
  
  .time_picker_container .preview_container{
    width: 80%!important;
  }

  .daysRow .col-lg-4{
    padding-left: 0px!important;
    padding-right: 0px!important;
  }

  .datePicker1 {
    //z-index: 999 !important;
    position: relative !important;
  }

  .input {
    background-color: #000 !important;
  }

  .greenBtn {
    background-color: #20b55c !important;
    color: white;
    border-radius: 50px;
    font-family: "CircularAirBold";
    font-size: 14px !important;
    cursor: pointer;
    transition: 0.3s;
    border: none !important;
    text-transform: capitalize !important;
    border-radius: 50px !important;
    padding: 5px 20px !important;
    &:hover {
      background-color: #1a9e4f !important;
    }
  }
  .datePicker2{
    padding-left: 0 !important;
    width: 100% !important;
    // display: flex !important;
    position: relative !important;
  }
  .DatePicker2 input {
    width: 100%!important;
  }
  .outside_container.active .modal_container{
    min-width: 300px!important;
  }
.toolDateCnt{
align-items: center !important;

}
.toolDateCnt-parent{
  text-align: -webkit-center !important;
  
}
  .rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 10px;
    text-align: center;
    font-size: 13px;
  }
}
.btn-space{
  padding: 10px;
}
.date-time-picker{
  border: none !important;
  width: 150px !important;
  padding-left: 0 !important;
  padding-top: 10px;
}
.row-padd{
  padding-left: 0 !important;
}
