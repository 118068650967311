@mixin box-shadow($params) {
  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}

$shadows: 0px 0px 5px rgba(0, 0, 0, 0.25), 0px 0px 0px #000 inset;
//@include box-shadow($shadows);


@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}

.appBtnScannerMenu {
  width: 50%;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    transform: scale(1.05);
  }
}
.navbar-div {


  .nav-icons {
    margin-left: 5px;
    margin-right: 5px;
    width: 27px !important;
    cursor: pointer !important;

    &:hover{
      animation: shake 1.2s cubic-bezier(.36,.07,.19,.97) both;
    }
  }
}
.button {
  float: right;
}

.logo {
  width: 60px;
  cursor: pointer ;
}

.fixMenu {
  right: 3%;
  height: 550px !important;
}

.sponsor {
  width: 160px;
  margin-left: 50%;
  height: 55px;
}

.navbar-toggler::after {
  display: none !important;
}

.navbar-custom .navbar-toggler {
  border: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: transparent;
    transform: scale(1.06);
  }

  &:focus {
    background: transparent;
    border: none !important;
    box-shadow: none;
    outline: none;
  }

  &:active {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.dropdownmenuCustom {
  top: 23px !important;
  left: -20px !important;
  min-width: 250px !important;
  border: none !important;
  -webkit-box-shadow: -4px 7px 5px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -4px 7px 5px 1px rgba(0, 0, 0, 0.1);
  box-shadow: -4px 7px 5px 1px rgba(0, 0, 0, 0.1);
  transition: 0.3s !important;
}

.dropdown {
  outline: none !important;
}

.itemCustom {
  color: #1bb0b0 !important;
  transition: 0.2s;
  padding: 10px;
  font-size: 15px;

  &:hover {
    width: 95%;
    color: #001e3d !important;
    background: #1bb0b0 !important;
    margin: auto;
    border-radius: 3px;
  }
}

.ManagerErrParent .ManagerErr{
  padding: 14px!important;
  color:#d16767;
  font-size:13px;
}

.ManagerErrParent .popover {
  border:none;
  -webkit-box-shadow: 4px 10px 22px -3px rgba(0,0,0,0.43);
-moz-box-shadow: 4px 10px 22px -3px rgba(0,0,0,0.43);
box-shadow: 4px 10px 22px -3px rgba(0,0,0,0.43);
}

.ManagerErrParent .arrow::before{
  border-bottom-color:transparent!important;
}

// .Navbar {
//   background: var(--navbar-background-color);
//   transition: 0.6s;
//   justify-content: space-between !important;
// }
.err-text {
  text-align: center !important;
  margin-top: 30px;
}
.err-btn {
  justify-content: center;
}
.appBar {
  position: relative;
}
.saveClose-Parent {
  align-self: flex-end;
  margin-right: 1rem !important;
  height: 40px;
  width: 40px;
  position: absolute;
  z-index: 5;
  margin-left: -1.5rem !important;
  background-color: transparent !important;
  color: rgb(15, 182, 168) !important;
  cursor: pointer;
  text-align: center;
  font-weight: 700;
  font-size: 25px;
  font-family: Verdana, Geneva, sans-serif;
}
.Categ-Modal {
  height: auto !important;
  width: auto !important;
}
@media (min-width: 576px) {
  .costume-modal-prof {
    max-width: 920px !important;
    margin: 3.75rem auto;
  }
}
.row {
  margin-right: 15px !important;
  margin-left: 15px !important;
}
.categ-save-btn {
  justify-content: center !important;
}

.Navbar-normal {
  // background: var(--navbar-background-color);
  background-color: #ffff;
  height: 80px !important;
  position: fixed !important;
  border-radius: 0 0 15px  15px  !important;
  box-shadow: 0px 0px 39.47px 8.53px rgba(29, 33, 33, 0.5);
  z-index: 4 !important;
  .bm-burger-button {
    top: 25px;
    right: 51px;
    width: 33px;
    height: 28px;
}

.bm-burger-button span:nth-child(2) {
  width: 38px !important;
}

.bm-burger-bars{
  height: 7px !important;
}

  .logo{
    margin-top:-12px;
  }

  .loginLogo{
    width:100px;
  }


}
.d-flex-costume-norm{
  margin-right: 4% !important;
}
.logo-flex{
  flex: 1;
}

.d-flex-costume{
  margin-right: 10% !important;
}

.image-nav{
  width: 50px !important;
  height: 50px;
  border-radius: 50px !important;

}




@media screen
and (max-device-width : 1600px)
{
  .lgUsr .Navbar-normal .bm-burger-button {
    right: 39px;
  }


}

@media screen
and (max-device-width : 1300px)
{
 
}

.fullWidth{
  width: 100%;
}


.maxWidth{
  height: 500px;
}


@media screen and (max-device-width : 1024px){
  .lgUsr .logo {
    margin-top: 0!important;
    margin-left: 0!important;
    width: 80px;
  }

  .lgUsr .Navbar-normal .bm-burger-button {
    right: 30px;
    top: 4px;
}



}


@media screen and (max-device-width : 1024px) and (orientation : landscape){

  .lgUsr .Navbar-normal .bm-burger-button {
    right: 38px;
    top: 3px;
}
}


@media screen and (max-device-width : 767px){
  .appBtnScannerMenu {
    width: 70%;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.3s;
  
    &:hover {
      transform: scale(1.05);
    }
  }
  .modal_how_it_works{
    height: 400px !important;
    }
  .lgUsr  .Navbar-normal {
    height: 60px !important;
  }

  .lgUsr .logo {
    width: 65px !important;
    margin-left: -7px!important;
    margin-top: -8px!important
  }

  .lgUsr .bm-burger-button {
    right: 2px!important;
    top: 1px!important;
    width: 32px;
    height: 28px;
}



  .lgUsr .logo-flex{
    max-width: 65px;
    margin-right: 0;
  }

  .lgUsr .sponsorTop{
    max-width: 200px;
    margin-left: -21px;

    img{
      max-width: 100%;
      object-fit: contain;
    }
  }

  .mobileMen{
    position: fixed;
    height: 60px !important;
    bottom:0;
    left:0;
    right:0;
    background: #fff;
    justify-content: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-right:0!important;
    -webkit-box-shadow: -2px -2px 23px -8px rgba(0,0,0,0.42);
  -moz-box-shadow: -2px -2px 23px -8px rgba(0,0,0,0.42);
  box-shadow: -2px -2px 23px -8px rgba(0,0,0,0.42);
  }
}
.numberCircle {
  border-radius: 50%;
    width: 20px;
    height: 20px;
    background: #f95656 !important;
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    position: absolute;
    top: -5px;
    right: -5px;
}
.notificationsContainer{
  position: relative;
}
.onlyNumber{
  border-radius: 50%;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 20px;
}
.popover-body{
  max-height:500px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.popover-body::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #76f5bc;
}

.popover-body::-webkit-scrollbar {
  border-radius: 5px;
  width: 5px;
  background-color: #fff;
}

.popover-body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #093d77;
}