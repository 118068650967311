$blueevents: #2d5683 !important;
$yellowEvents: #f4d35e !important;
$greenEvents: #00b4b1 !important;
$iconsColor: #0ee6e0 !important;
$purpleColor: #4b0082 !important;
$followColor: magenta !important;
$seeProfileColor: green !important;
$reportColor: red !important;
$blColor: #2d5683;

@import "../../../Assets/css/variable";
.eventProfilePic-MakeEvents{
  height: 70px !important;
  width: 70px !important;
  max-width: 70px !important;
  border-radius: 50px;
  cursor: pointer;
  border: none;

  object-fit: cover;
}
.event {
  background: rgba(255, 255, 255, 0.4);
  // background-color: bisque;
}

.imageBox {
  width: 100px !important;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
}

.border {
  border-width: 1px;
  border-style: solid;
  border-color: red;
}

.modal-backdrop.show {
  opacity: 0.5;
  width: 100%;
  height: 100%;
}

.datePicker {
  z-index: 999 !important;
  position: relative !important;
}


.eventUsrName {
  text-transform: capitalize;
  font-family: $fontBold;
  font-size: 20px;
  color: $blColor;
}


.eventGeneral {
  margin-top: 20px;
  border-top: 1px solid #eee;
  padding-top: 10px;

  input {
    border-radius: 10px;
    padding-left: 15px;
    font-size: 15px !important;
    background-color: #2d5683;
    height: 50px;
  }

  .invisible {
    height: 1px !important;
    margin-top: 0px;
    display: none;
  }

  .form-control.is-valid {
    border-color: white;
  }

  .form-control.is-invalid {
    border-color: white;
    background-image: none;
  }

  .form-control.is-invalid:focus {
    border-color: white;
  }

  //remove arrows from number input
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.popupEventForm .form-control {
  color: #ffffff !important;
}

.mkeventsWhere {
  margin-top: 20px;
}


.eventRowTitle {
  font-family: $fontNormal;
  color: $blColor;

}

.eventsbottom {
  display: block;
}

.eventGeneral #combo-box-demo {
  border: none;
  border-radius: 15px;
  height: 28px;
  padding-left: 10px;

}

.eventGeneral .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0px !important;
}

.eventsbottom .svg-inline--fa {
  color: #fff;
}

.eventsbottom .input-group-append {
  border: none;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top: none;
  border-right: none;
  border-bottom: none;
  background-color: #2d5683;

  .btn {
    height: 50px !important;
  }

  input {
    height: 50px;
  }
}

.mkCompleteBottom {
  border-top: none;
  padding-top: 20px;

  .MuiAutocomplete-popupIndicator {
    position: relative;
    top: -6px;
    border-left: 1px solid #fff;
    border-right: none;
    border-radius: 0;
  }

  .MuiAutocomplete-popupIndicatorOpen {
    border-right: 1px solid #fff;
    border-left: none;
  }

  .MuiAutocomplete-popupIndicator svg {
    font-size: 35px !important;
  }

  .MuiAutocomplete-clearIndicator {
    margin-top: -10px !important;
    margin-right: 5px;
  }

}

.mkComplete {
  .MuiAutocomplete-popupIndicator {
    position: relative;
    top: -6px;
    border-left: 1px solid #fff;
    border-right: none;
    border-radius: 0;
  }

  .MuiAutocomplete-popupIndicatorOpen {
    border-right: 1px solid #fff;
    border-left: none;
  }

  .MuiAutocomplete-popupIndicator svg {
    font-size: 35px !important;
  }

  .MuiAutocomplete-clearIndicator {
    margin-top: -10px !important;
    margin-right: 5px;
  }


}


.eventsbottom #autocomplete {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #2d5683;
  color: #ffffff;
  border-right: 1px solid #fff !important;
  border-left: none;
  border-top: none;
  border-bottom: none;
  height: 50px;

  svg {
    fill: #fff !important;
  }
}

#botCategories {
  background-color: #2d5683;
  border-left: none;
  border-bottom: none;
  border-top: none;
  height: 50px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.evtDescArea {
  background-color: #2d5683;
  border-radius: 10px !important;


}


.evtDescArea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff !important;
}

.evtDescArea::-moz-placeholder { /* Firefox 19+ */
  color: #fff !important;
}

.evtDescArea:-ms-input-placeholder { /* IE 10+ */
  color: #fff !important;
}

.evtDescArea:-moz-placeholder { /* Firefox 18- */
  color: #fff !important;
}


.evtBotAreaSpace {
  border-top: solid 1px #ced4da;
  padding-top: 20px;
  margin-top: 20px !important;
}

.eventsbottom .dropdown-menu .dropdown-item {
  color: #495057;
}

.eventsbottom .dropdown-menu .dropdown-item:hover {
  color: #ffffff;
}

.mkEvtSelAge .form-control {
  background-color: #2d5683 !important;
  color: #ffffff !important;
  height: 50px;
  border-radius: 10px;

}

.popupEventForm input {
  background-color: #2d5683 !important;
  height: 50px;
  border-radius: 15px;
}

.popupEventForm {
  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff;
  }

  input::-moz-placeholder { /* Firefox 19+ */
    color: #fff;
  }

  input:-ms-input-placeholder { /* IE 10+ */
    color: #fff;
  }

  input:-moz-placeholder { /* Firefox 18- */
    color: #fff;
  }
.nrmgreenBtnEvt-evt{
  background-color: #20b55c !important;
  color: white;
  border-radius: 50px;
  font-family: "CircularAirBold";
  font-size: 17px ;
  cursor: pointer;
  transition: 0.3s;
  border: none !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
  padding: 8px 35px ;

  &:hover {
    background-color: #1a9e4f !important;
  }
}

  .nrmgreenBtnEvt {
    background-color: #20b55c !important;
    color: white;
    border-radius: 50px;
    font-family: "CircularAirBold";
    font-size: 17px !important;
    cursor: pointer;
    transition: 0.3s;
    border: none !important;
    text-transform: capitalize !important;
    border-radius: 50px !important;
    padding: 8px 35px !important;

    &:hover {
      background-color: #1a9e4f !important;
    }
  }

  .borderTop {
    border-top: 1px solid #dedcdc;
  }

  .selectInput {
    background-image: linear-gradient(45deg, transparent 50%, #fff 60%), linear-gradient(135deg, #fff 42%, transparent 50%) !important;
    background-position: calc(100% - 20px) 21px, calc(100% - 10px) 21px, 100% -3px;
    background-size: 7px 10px, 10px 7px;
  }

  .timeColor {
    color: #2d5683 !important;
  }

  .evtDatepicker {


    input {
      width: 100%;
      border: none !important;
      background: #eee !important;
      border-radius: 5px;
      font-size: 16px !important;
      text-align: center;
      cursor: pointer;
      margin-bottom: 10px;

    }
  }
}


.MuiInputBase-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff !important;
}

.MuiInputBase-input::-moz-placeholder { /* Firefox 19+ */
  color: #fff !important;
}

.MuiInputBase-input:-ms-input-placeholder { /* IE 10+ */
  color: #fff !important;
}

.MuiInputBase-input:-moz-placeholder { /* Firefox 18- */
  color: #fff !important;
}


@media screen and (max-device-width: 767px) {
  .eventProfilePic-MakeEvents{
    height: 55px !important;
    width: 55px !important;
    max-width: 55px !important;
  }
  .nrmgreenBtnEvt-evt{
    background-color: #20b55c !important;
    width: 150px !important;
    min-width: 150px !important;
    color: white;
    border-radius: 50px;
    font-family: "CircularAirBold";
    font-size: 13px !important;
    cursor: pointer;
    transition: 0.3s;
    text-transform: capitalize !important;
    border-radius: 50px !important;
    padding: 5px !important;
  
    &:hover {
      background-color: #1a9e4f !important;

    }
  }
  .marginTop{
    margin-bottom: 10px !important;
  }
  .popupEventForm .borderTop {
    border: none;
  }

  .mkeventsWhere {
    margin-bottom: 5px !important;
  }

  .mkCompleteBottom {
    border: none !important;
    padding-top: 0 !important;


    .MuiAutocomplete-popupIndicator {
      top: 2px;
    }

    .MuiAutocomplete-clearIndicator {
      margin-top: 5px !important;
      margin-right: 0px;
    }

    .MuiAutocomplete-root {
      width: 100% !important;
    }

  }


  .mkComplete {
    border: none !important;
    padding-top: 0 !important;


    .MuiAutocomplete-popupIndicator {
      top: 2px;
    }

    .MuiAutocomplete-clearIndicator {
      margin-top: 5px !important;
      margin-right: 0px;
    }

    .MuiAutocomplete-root {
      width: 100% !important;
    }

  }

  .popupEventForm .nrmgreenBtnEvt {
    font-size: 12px !important;
  }

  .popupEventForm .eventUsrName {
    font-size: 16px;
  }
}
.eventUsrName{
  padding-left: 30px !important;
}
.makeeventlocation {
  display: block;
  z-index: 99;
}

.zingMapComponent .makeEvents .location-search-input {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.zingMapComponent .makeEvents .location-search-input {
  padding-top: 0px;
}

.zingMapComponent .makeEvents .location-search-input {
  max-width: 100%;
  border: none;
  background-color: #2d5683 !important;
  border-radius: 10px;
  color: #ffffff !important;
  width: 100%;
  padding-right: 44px !important;
  padding-left: 12px !important;
}

// .zingMapComponent {
//   height: 50px !important;
// }

.makeeventlocation .zingMapComponent .autocomplete-dropdown-container {
  position: absolute;
  z-index: 9999;
  display: block;
  border: none;
}

.makeeventlocation .location-search-input {
  color: #ffffff !important;
}

.makeeventlocation .suggestion-item {
  color: #000000 !important;
}

.makeeventlocation .suggestion-item--active {
  color: #ffffff !important;
  background-color: #2d5683 !important;
}

.makeEvents .input-group-append {
  position: absolute;
  top: 11px;
  right: 13px;
  border-left: solid 1px #ffffff;
}

.handicapIcon {
  width: 50px;
  height: 50px;
  background: #2d5683 !important;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:active {
    background: #1a9e4f !important;
  }

  &:focus {
    background: #1a9e4f !important;
  }

  // &:hover {
  //   background-color: #1a9e4f !important;
  // }
}

.iconBtnSpace svg{
  margin:0px 5px!important;
}
.mkevent .zingMapComponent{
  width:auto!important;
  height:auto!important;
  margin: 0px 10px!important;
}

.bannerBusinessContainer {
  border-radius: 15px;
  background-color: white !important;
  -webkit-box-shadow: 6px 6px 28px -6px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 6px 6px 28px -6px rgba(0, 0, 0, 0.47);
  box-shadow: 6px 6px 28px -6px rgba(0, 0, 0, 0.47);
  margin: 10px 0px;
  padding-top: 10px;
  padding-bottom: 10px;

  .profileImgBanner {
    height: 70px !important;
    width: 70px !important;
    max-width: 70px !important;
    border-radius: 50px;
    border: none;
    object-fit: cover;
  }

  .companyName {
    color: #2d5683;
    font-family: "CircularAirBook";
    font-weight: bold;
    font-size: 18px;
  }
  .address {
    color: #2d5683;
    font-family: "CircularAirBook";
  }
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
}
.backgrounButton-normal {
  background-color: #2d5683 !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 48px !important;
  margin-top: 1px !important;
}
