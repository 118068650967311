.messagesHeader {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}

.messagesTypes {
  display: flex;
  width: 100%;

}

.friendsType {
  background-color: #3AB54A !important;
}

.myEventsType {
  background-color: #158B66 !important;

}

.otherEventsType {

  background-color: #2291B0 !important;

}

.messagesButtons {
  width: 100%;
  border-radius: 0 !important;
  border-color: transparent !important;
}

.friendLogoImage {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.lBorderRadius {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

.rBorderRadius {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}


.messageInbox {
  margin: 10px;
  padding: 10px;
  display: flex;
  border-radius: 5px;
  color: #757575;
  background: #fff !important;
  -webkit-box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.03);
  box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.03);
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    transform: scale(1.05);
    color: #fff !important;
  }
}

.messageInbox.friendsType:hover {
  background: #3AB54A !important;
}

.messageInbox.friendsType.active {
  background: #3AB54A !important;
  color: #fff !important;
  transform: scale(1.05);
}


.messageInbox.myEventsType:hover {
  background: #158B66 !important
}

.messageInbox.myEventsType.active {
  background: #158B66 !important;
  color: #fff !important;
  transform: scale(1.05);
}


.messageInbox.otherEventsType:hover {
  background: #2291B0 !important;
}

.messageInbox.otherEventsType.active {
  background: #2291B0 !important;
  color: #fff !important;
  transform: scale(1.05);
}

.textCont {
  flex-direction: column-reverse !important;
  min-height: 290px;
}

.usersContainer {
  background-color: #eeeeee;
  border-radius: 10px;
  color: black;
  width: 32%;
  min-width: 32%;
  height: auto;
  max-height: 372px;
  
  overflow: auto;
}

.noMsg {
  text-align: center;
  padding: 20px;
}

.messageText {
  padding: 5px 15px;
  border-radius: 0.4em;

}

.topInbox {
  max-width: 75%;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  label {
    cursor: pointer;
  }
}


.textRight {
  margin-right: 10px;
  background-color: #2d5683;
  border-radius: 10px 0px 10px 10px;
  color: #fff;
  font-size: 14px;
  position: relative;
  // word-break: break-all;
  word-break:   break-word;

}


.textLeft {
margin-left: 10px;
text-align: left;
background-color: #e2e2e2;
border-radius: 0px 10px 10px 10px;
font-size: 14px;
color: #898989;
position: relative;
text-align: left;
height: auto ;
// width:100%;
word-break: break-word;
}

.myEventsType .logo_and_expand img {
  background: #158B66 !important;
  padding: 5px;
}

.otherEventsType .logo_and_expand img {
  background: #2291B0 !important;
  padding: 5px;
}


.logo_and_expand {
  display: contents;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  padding: 4px;
  cursor: pointer;
}

.centerlogo {
  margin: 0 8px 4px 8px;
  display: flex;
  align-items: flex-start;
}

.centerExpand {
  cursor: pointer;
  display: flex;
  height: 50%;
  font-size: 25px;
  align-items: flex-end;
  justify-content: center;
}

.messageReplyContainer {
  position: sticky;
  margin-top: 10px;
  padding: 10px;
  font-size: 15px !important;
  bottom: 0!important;
  background: #eeeeee;
  // padding: 0;
  justify-content: space-between;
  width: 100% !important;
  // display: flex;
}
.textarea-style{

  background-color: #fff !important;
  color: #2d5683 !important;
  -moz-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  -webkit-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
    // border: none;
    background-color: rgb(255, 255, 255) !important;
    font-size: 15px !important;
   color: #2d5683 !important;
   width: 100% !important;
   overflow: auto !important;
   max-height: 70px !important;
   min-height: 35px !important;
border-radius:7px !important;
outline: none !important;

}
.textarea-style::-webkit-scrollbar{
  border-radius: 5px;
  background-color: white;
  border: 1px solid rgb(158, 158, 158);
  width: 5px;

 }
 .textarea-style::-webkit-scrollbar-thumb{
  border-radius: 5px;
  background-color: rgb(158, 158, 158);
  border: 1px solid rgb(158, 158, 158);

 }

  .textarea-style.form-control{
  width:   100% !important;
  
  }
.text-reply {
  width: 70%;
::placeholder{
  color: #2d5683 !important;
  font-size: 14px !important;

}
  input {
  -moz-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  -webkit-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
    // border: none;
    background-color: rgb(255, 255, 255) !important;
    border-radius: 15px;
    font-size: 15px !important;
   color: #2d5683 !important;
   width: 100% !important;
  }
  .form-control:focus {
    
    -moz-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6) !important;
    -webkit-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6) !important;
    box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6) !important;
  }
}

.send_message_button {
  border: none !important;
  border-radius: 50% !important;
  font-size: 20px !important;
  // margin-left: -17px;
}

.emptyMessage {
  background: #3ab54a !important;
}

.chatContainer {
  width: 100%;
  height: 362px;
  overflow: auto;
  margin: 5px;
  position: relative;

  .usrmsg {
    width: 100%;
    align-items: flex-start;
    padding-top: 5px;
    padding-bottom: 10px;
  }

}


.usersContainer::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #fff;

}

.usersContainer::-webkit-scrollbar {
  border-radius: 5px;
  width: 5px;
  background-color: #fff;

}

.usersContainer::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #c4c4c4;
}


.chatContainer::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #fff;

}

.chatContainer::-webkit-scrollbar {
  border-radius: 5px;
  width: 5px;
  background-color: #fff;

}

.chatContainer::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #c4c4c4;
}


.allChat {
  margin-top: 10px;
  display: flex;
  width: 100%;
  background-color: #eeeeee !important;
  border-radius: 15px;
  padding: 10px;

  .MuiDivider-root {

    background: #f1f1f1;
  }
}


@media screen and (max-device-width: 1024px) {
  .modal-dialog {
    max-width: 90% !important;
  }

}

.rowColsMessages{
  display: flex !important;
  // border: solid blue;
  width: 100% !important;

}
@media screen and (max-device-width: 767px) {
  // .parrent-buttons{
  //   width: 65px;
  //   display: flex;
  //   justify-content: space-between;
  // }
  .text-reply {
    // width: 100% !important;
    // position:absolute !important;
    // left: -100px !important;
  }
  .delete_conv_button {
    margin-top: 7px;
    zoom: 0.7;
    // font-size: 10px !important;
    margin-left: 5px;
  }
  .rowColsMessages{
    width: 100% !important;
    display: initial ;
  }
  .modal-dialog {
    max-width: 99% !important;
  }


  .messagesTypes .messagesButtons {
    font-size: 12px !important;
  }


  .allChat {

    padding: 5px;
    margin-top: 10px;
    width: 100%;
    background: #eeeeee;
    border-radius: 15px;
    // padding: 10px;
    .chatContainer {
      width: 100%;
      height: 362px;
      overflow: auto;
      margin: 5px;
      position: relative;
    }

    .usersContainer {
      height: 600px;
    }

    .textCont {
      max-height:200px;
      height: auto;
      padding: 3px !important;
    }

    .textLeft, .textRight {
      font-size: 13px !important;
    }

    .friendLogoImage {
      height: 25px;
      width: 25px;
    }

    .messageInbox {
      justify-content: start;
      display: flex;
    }

    .logo_and_expand {
      justify-content: center;
      flex-direction: column;
    }

    .topInbox {
      max-width: 100%;
      font-size: 13px;
      // margin: 0 auto;
    }

    .messageInbox {
      margin: 5px;
      padding: 2px;
    }

    .messageReplyContainer {
      // padding: 0;
      // justify-content: space-between;
      // width: 100% !important;
      // display: flex;
      padding: 0;
      justify-content: space-between;
      width: 127% !important;
      display: flex;
      margin-left: -100px;
      .row {
        width: 100%;
        margin: 0 !important;
      }
    }

    .send_message_button {
      // margin-left: 10px;
      zoom: 0.7;
      margin-top: 7px;
    }

  }
}
.childChatAndSend{
  // padding:  0 10px 0 10px ;
  padding-left: 2px;
}
.parentChatAndSend{
  width: 100% !important;
}

.parrent-buttons{
  // width: 85px;
  display: flex;
  justify-content: flex-end;
}

.delete_conv_button {
  // border: none !important;
  // border-radius: 50% !important;
  // font-size: 25px ;
  // margin-left: -5px;
  background: brown !important;
  border: none !important;
  border-radius: 50% !important;
  font-size: 20px !important;
  // margin-left: -17px;
}

.delete_message_button {
  border: none !important;
  background: transparent !important;
  font-size: 15px !important;
  margin-left: -5px;
}

