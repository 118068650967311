/*Components/firstpage/header/header.scss*/
@import "../../../Assets/css/variable";


$blueevents: #0ac2bb;
$yellowEvents :#fff!important;
$greenEvents :#04C9C2!important;
$iconsColor :#9B0057!important;
$blueNew: #009BE4;
.modal-video-close-btn:before {
    -moz-box-shadow: 0.25px 0.25px 0.25px 0.25px #ccc;
    -webkit-box-shadow: 0.25px 0.25px 0.25px 0.25px #ccc;
    box-shadow: 0.25px 0.25px 0.25px 0.25px #ccc;
}

.modal-video-close-btn:after {
    -moz-box-shadow: 0.25px 0.25px 0.25px 0.25px #ccc;
    -webkit-box-shadow: 0.25px 0.25px 0.25px 0.25px #ccc;
    box-shadow: 0.25px 0.25px 0.25px 0.25px #ccc;
}
.businessOfferDiv{
    width: 100% !important;
    padding: 20px !important;
}

.center.customBgNew{
    overflow: inherit!important;
}
.descriptionAbso{
    color: rgb(255, 255, 255);
    position: absolute;
    z-index: 100 !important;
    font-size: 15px !important;
    padding: 10px ;
}

.html5-main-video{
    max-width: 80% !important;
    width: 80% !important;
}
.modalVideoBody{
    z-index: 0 !important;
    top: 0 !important;
}
.videoModal .modal-video {
    background-color: transparent !important;
    z-index: 0 !important;

}
.modal-video-inner{
    vertical-align: top !important;
    padding-top: 40px !important;
    background-color: transparent !important;
    height: 50% !important;
}
.modalBodySmall{
    z-index: 100000 !important;
 background-color: transparent !important;
}
.videoModal {

    position: absolute !important;
    top:auto !important;
    right:auto !important;
    left:auto !important;
    bottom:0 !important;


    width: 370vh !important;
    height: 170px !important;
    overflow-y: auto;

align-content: center !important;

    justify-content: center !important;
    align-self: center !important;

    // z-index: 10000 !important;
    // overflow-y: scroll !important;
    // overflow-x: hidden !important;
    // color: red ;
    // height: 800px !important;
    // width: 100% !important;

  .modal-content {
    height: 100% !important;
    justify-content: center;
    color: #fff !important;
    width: 100% !important;
    background: transparent !important;

  }

  .textOfferPopup {
    font-size: 25px;
    font-family: $fontBold;
    color: #fff;
    // z-index: 10000000 !important;
  }
}

.videoModalHeader{
    max-height: 400px !important;
    height: auto !important;
    z-index: 10000 !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    // color: red ;
}
.descriptionStl{
    background-color: transparent !important;
    text-align: center !important;
    font-size: 20px ;

}
// .modal-video-body{
//     width: 50% !important;
//     height: 1300px !important;
// }


// .modalVideoBody{
//     background-color: red !important;
//     background: red !important;
// }
.calendarHeader {
  //  background-color: rgba(45, 86, 131, 0.7) !important;
    border-radius: 20px;
    width: 100%;

    z-index: 9;
}
.calendarHeaderInput{
    text-align: left!important;
}


.autocomplete-dropdown-container div{
    padding-left:15px;
    padding-top:7px;
    padding-bottom:7px;
    padding-right:5px;
    font-size:14px;
}
.yellowBox img{
    width: 100%;
    height: 100%;
	object-fit:cover;
    border-radius: 29px;
}

.sliderText{
    position: absolute;
    color: #fff;
    font-size: 40px;
    letter-spacing: 2px;
    line-height: 40px;
    text-align: center;
    top: 5%;
    left: 13%;
    right: 0;
    z-index: 3;
    display: block;
    width: 70%;

    .first{
        /* margin-top: 50px; */
        font-weight: 700;
        letter-spacing: -1px;
    }

    .firstLight{
        font-weight: 300;
        font-family: $fontLight;
        letter-spacing: -1px;
    }


}

.textOfferPopup{
    height: 300px;
    overflow: auto;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 50px;
    font-size: 25px;
    font-family: $fontBold;
}

.container-fluid{
    padding:0;
}


.textParent{
   text-align: center;

}

.hpCategInput{
    height:68px;

    .MuiAutocomplete-popper select {
        font-size:5px!important;
    }

    .MuiAutocomplete-clearIndicator {
        margin-top: -12px !important;
        margin-right: 5px;
    }

    .MuiAutocomplete-popupIndicatorOpen{
        border-right: 1px solid #acc1cb!important;
        border-left:none!important;
    }

    .MuiAutocomplete-popupIndicator{
        position: relative;
        top: -7px;
        border-left: 1px solid #acc1cb;
        border-right:none;
        border-radius: 0;

        svg{
            font-size: 35px!important;

        }
    }


}


.container::after {
    content: "";
    display: table;
    clear: both;
}

.container-fluid {
    min-height: 100%;
    }


.container {
     position: relative;
     min-height: 100%;
    }




.textParent{
    display: flex;
    justify-content: center;

}


.LeftParent p, .RightParent  p{
    background: rgba(255,255,255,0.4);
    border-radius: 24px;
    padding: 20px;
    text-align: center;
    color: #fff;
    font-size: 20px;
}


.shape{
    position: absolute!important;
    display: block;
    perspective: 100vw;
    cursor: pointer;
    height: 600px;
    top: 18%;
    z-index: 2;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 90%!important;
}




  @keyframes animatedgradient {
      0% {
          background-position: 0% 50%;
      }
      50% {
          background-position: 100% 50%;
      }
      100% {
          background-position: 0% 50%;
      }
  }



.headerJs{
    position: relative;
    z-index: 1;
}



.firstItem{
    position: relative;
    z-index: 5;
    top:0;
    left:0;
    bottom: 0;
    right: 0;
    background: rgba(76, 242, 167, 0.3);
    height: auto;
    border-radius: 50px;
    padding: 30px 10px 30px 10px;
    display: flex;
    min-width: 100%;
    min-height: auto;
   box-shadow: 0px 0px 106.47px 10.53px rgba(29, 33, 33, 0.72);

   transform: rotateY(15deg);
   -moz-transform: rotateY(15deg);
   -webkit-transform: rotateY(15deg);
   -o-transform: rotateY(15deg);
   transition: 1s;

    &:hover{
        transform: rotate(0);

    }

}

.imageHead .awssld__content img{
   height: 100vh;
}

.imageHead .awssld__content{
    background: #fff;
}

.imageHead .awssld {
    height: calc(100vh - 110px); /*50+60*/
}

.awssld__container{
    position: relative;
}

.imageHead{
    position: relative;
    z-index: 1;
    background-size: cover;
    width: 100%;
    height: auto;

}

.imageHead img{
    max-width: 100vw;
    width:100vw;
    -webkit-filter: drop-shadow(12px 12px 25px rgba(0,0,0,0.5));
    filter: drop-shadow(12px 12px 25px rgba(0,0,0,0.5));
    margin-top: -5%;
}


.container-fluid{
    padding:0!important;
    margin:0!important;
}


.Header{
    width: 100%;
    overflow: hidden;
}


.parent{
    display: flex;
    flex-direction: column;
}

.imageHead .awssld__bullets, .awssld__controls{
    display:none;
}

input:focus{
    outline:none!important;
    box-shadow:none!important;
}

.customForm input, .customForm select{
    border-color: rgba(45,86,131,0.7)!important;
    color:#fff!important;
    height: 68px!important;
    background: rgba(45,86,131,0.7);
    border-radius: 20px!important;
    padding-left:10px;
}

.dropdown-toggle::after{
    font-size: 25px;
    margin-left: 0;
    vertical-align: 0;
    position: absolute;
}

.customForm .input-group{
    flex-wrap: inherit;
    margin-bottom: 10px;
}

.customForm .svg-inline--fa{
    font-size:20px;
    right: 17px;
    position: absolute;
}


.input-group-text{
   // background: #fff!important;
    background: transparent!important;
    border-color:transparent!important;
    color:#fff!important;
}

.input-group-append .dropdown-toggle{
    right: 63px;
    background: #306d82;

    &:focus{
        box-shadow: none;
        background-color:transparent!important;
        border-color: transparent!important;
        outline:none!important;
        box-shadow: none!important;
    }
}

.addContainer {
    overflow: hidden;
}

.btn-secondary.dropdown-toggle, .secondary.dropdown-toggle {
    background-color: transparent!important;
    border-color: transparent!important;
}

.customForm .dropdown-menu.show{
    width: 383px;
    transform: translate3d(-348px, 49px, 0px) !important;
    transition: 0.3s;
    background: #2f6e82;
    top: 4px !important;
    height: 200px;
    overflow: auto;
}



.dropdown-menu::-webkit-scrollbar-track
{
	border-radius: 5px;
    background-color: #fff;

}

.dropdown-menu::-webkit-scrollbar
{	border-radius: 5px;
	width: 5px;
    background-color: #fff;

}

.dropdown-menu::-webkit-scrollbar-thumb
{	border-radius: 5px;
	background-color: #093d77;
}



.noBorder{
    border-right:none!important;
}

.input-group{
    margin-bottom:10px;
}


select {
    background-image: linear-gradient(45deg, transparent 50%, #009d9b 60%), linear-gradient(135deg, #009d9b 42%, transparent 50%) !important;
    background-position: calc(100% - 20px) 17px, calc(100% - 10px) 17px, 100% -3px;
    background-size: 8px 10px, 10px 10px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.box .icon img{
    transition: 0.3s;
}

.box{
    width: 100%;
    height: auto;
    background: rgba(45,86, 131,0.7);
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    border-radius: 40px;
    height: 130px;


    &:hover{
        background: rgba(45,86, 131,0.9);

        .icon img{
           transform: scale(1.1);
            animation: pulseHP 2s infinite;
            border-radius: 50%;
        }
    }
}


@keyframes pulseHP {
	0% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	70% {
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

.box .textbox .title{
    margin-top: -1px;
    font-size: 24px;
    letter-spacing: -1px;
    color:#13e567;
    font-family: $fontBold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;


}

.customForm input .react-datepicker-wrapper{
    padding-right:20%!important;
}

.sliderText {
    position: absolute!important;
}

.box .textbox {
    text-align: left;
    padding: 21px 0px 15px 0px;
    overflow: hidden;

}

.shape{
    height:auto;
}

.box .textbox .date{
    font-size: 18px;
    font-family: $fontBold;
    color: #0cb6fe !important;
    border-radius: 4px;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

}

.searchText{
    text-align: center;
    line-height: 45px;
    margin-bottom:40px;
    margin-top: 15px;
}

.btext{
    color:#fff;
    font-size:30px;
    font-family: $fontBold;
    letter-spacing: 0.1px;
    line-height:30px;
}


.ltext{
    color:#fff;
    font-size:30px;
    font-family: $fontLight;
    letter-spacing: 0.1px;
    line-height:30px;
}
.txtParents{
    display: flex !important;
    flex-direction: column !important;
}
.text1{
    font-weight: bold !important;
    text-align: start !important;
}
.text2{
    font-weight: none !important;
    text-align: start !important;
}
.eventBtn{
    background:#093d77!important;
    font-size:17px!important;
    padding: 0.5em 3em!important;
    border-radius: 41px;
    margin-top:20px;
    -webkit-box-shadow: 6px 6px 16px -4px rgba(0,0,0,0.38);
    -moz-box-shadow: 6px 6px 16px -4px rgba(0,0,0,0.38);
        box-shadow: 6px 6px 16px -4px rgba(0,0,0,0.38);
    border:none!important;
    transition: 0.3s;
    color:#fff;
    font-family: $fontBold;
    width: 80% !important;

    &:hover{
        background: #042244 !important;
    }
}

.box .textbox .location{
    font-size: 18px;
    color:#fff;
    margin-top: -5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

}

.bgcolor{
    background: transparent;
    width: 100%;
    height: auto;
}

.bgbox{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

}

.sliderText {
    margin-top:50px;
}


.bgbox .icon{
    color: #fff;
    margin-left: -30px;
    margin-top: 14px;

}



input{
    font-size:18px!important;
    color:#666!important;
}


.customForm .input-group-append {
    margin-left: -1px;
    position: absolute;
    right: 0;
    align-self: center;
    z-index: 9;


}


.center #combo-box-demo2 {
    height:32px !important;
    background-color: transparent !important;
  }

  .categoryInput {
    border-radius: 20px;
    background-color: rgba(45, 86, 131, 0.7) !important;
    width: 100%;
  }


.customForm .input-group-append .btn, .input-group-prepend .btn{
    padding-right: 38px!important;
}

.customForm .input-group-append::before{
    content:"";
    border-left:1px solid rgba(255, 255, 255, 0.6);
    position: relative;
    left:-20px;
    height:40px;
}


.customForm .arrowRight .input-group-append::before{
    left:5px!important;
}


.icon {
    filter:drop-shadow(3px 6px 1px rgba(0, 0, 0, 0.15));
}


.LeftParent::-webkit-scrollbar-track
{
	border-radius: 5px;
    background-color: #fff;
    border:2px solid #76f5bc;

}

.LeftParent::-webkit-scrollbar
{	border-radius: 5px;
	width: 5px;
    background-color: #fff;

}

.LeftParent::-webkit-scrollbar-thumb
{	border-radius: 5px;
	background-color: #093d77;
}


.RightParent::-webkit-scrollbar-track
{
	border-radius: 5px;
    background-color: #fff;
    border:2px solid #76f5bc;

}

.RightParent::-webkit-scrollbar
{	border-radius: 5px;
	width: 5px;
    background-color: #fff;

}

.RightParent::-webkit-scrollbar-thumb
{	border-radius: 5px;
	background-color: #093d77;
}



.LeftParent{
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding-right:60px!important;
    max-width: 550px!important;
}

.LeftParent .box{
    height:125px;
}


.bm-overlay{
    display:none;
}


.RightParent{
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding-right:60px!important;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 43vh);
    max-width: 550px!important;
}

.yellowBox{
    max-width: 100%;
    width: 100%;
    height: auto;

    background: #f9c528;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    border-radius: 40px;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    img{
        max-width: 100%;
        object-fit: cover;
    }
    &:hover{
        background: #ffe082;
    }

    .textOffer{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
}


.vidBox{
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    height: 124px;
    overflow: hidden;
    border-radius: 40px;
    display: flex;
    justify-content: center;


}
.parDiv{
    width: 100% !important;
    -webkit-box-shadow: 6px 6px 16px -4px rgba(0,0,0,0.38);
    -moz-box-shadow: 6px 6px 16px -4px rgba(0,0,0,0.38);
        box-shadow: 6px 6px 16px -4px rgba(0,0,0,0.38);
}
.vidBoxImg{


    // object-fit: cover;
    // max-width: 100% !important;
    width: 100% ;
    // min-width: 100% !important;
    height: 125px;
}

.vidBoxBtn{
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    height: 125px;

    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.vidBoxBtn-parrent{
    width: 100%;
    position: absolute !important;
    height: 100% !important;
    &:hover{
        transform: scale(1.1);
    }
}



.textOffer{
    font-size:28px;
    font-family: $fontBold;
    color:#093d77;
    text-align: center;
}

.center{
    padding-left:60px!important;
    padding-right:60px!important;
}

.input-group-append .dropdown-toggle{
    background-color:transparent;
    border-color: transparent;
}

.dropdown-menu .dropdown-item{
    transition:0.3s;
    font-size: 20px;
    color:#fff;
    &:hover{
        background: #285a6a;
        color:#fff;
    }

}
.dropdown-divider {
    margin: .2rem 0!important;
}


.react-datepicker-wrapper input{
    border: 1px solid #ced4da;
    min-width: 100%;
    padding-left:10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

}

.customForm input{
    font-size:1vw!important;
    padding-left:25px;
    padding-right: 15%;
    font-family: $fontNormal;
    width: 100%;

    &:focus{
        background: rgba(45, 86, 131, 0.7) !important;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #fff;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: #fff;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: #fff;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: #fff;
      }
}

.sponsorImg{
     max-width: 100%;
    max-height: 100%;
    object-fit: cover;

}

.Imgbox{
    margin: auto;
    display: block;
    margin: 0;
    position: relative;
    overflow: hidden;
}


.shape  .zingMapComponent{
    height:auto!important;
}


.Videobox > div{
    width:388px!important;
    height:96px!important;
    margin: auto!important;
    display: block!important;
    margin: 0!important;
    position: relative!important;
    overflow: hidden!important;
}

.sponsorVideo{
    width:388px!important;
    height:96px!important;
}

.no-padding{
    padding-left: 0;
    padding-right: 0;
}
.testing{
    margin-top: 5%;
}

.awssld__next{
    position: relative;
}

/*************Start After Login******************/
.lgUsr {

    .RightParentBg{

        padding:30px 20px 20px 20px ;
        height:auto;
        border-radius: 40px;
        background-color: rgba(76, 242, 167, 0.7);
        -webkit-box-shadow: -2px 5px 27px 3px rgba(0,0,0,0.12);
        -moz-box-shadow: -2px 5px 27px 3px rgba(0,0,0,0.12);
        box-shadow: -2px 5px 27px 3px rgba(0,0,0,0.12);
        height: 647px;
        border: 2px solid #09b7735e;
          }

          .RightParent{
              padding-right:20px!important;
              max-width: 500px !important;
              height: 537px;
              margin:0 auto;
          }

    }


/*************Stop After Login******************/


@media screen
and (min-device-width : 1921px)
and (max-device-width : 3000px)
{

    .bgbox .icon {
        font-size: 2.5vw;
    }

    .box .textbox .title{
        font-size: 1.2vw;
    }

    .box .textbox .location{
        font-size: 1vw;
    }

    .box .textbox .date {
        font-size: 0.9vw;
    }

    .box .textbox {
        padding: 2px 0px 10px 0px;
    }

    .container{
        max-width:1500px!important;
    }


    .imageHead{
        top: -200px!important;
    }



    .LeftParent{
        height:538px;
    }


    .RightParent{
        height:538px;
    }

    .shape {
        top: 13%;
    }


    .react-datepicker-wrapper input{
        // min-width: 434px;
    }

    .customForm .dropdown-menu.show {
        width: 430px;
    }

    .eventBtn {
        font-size: 1.1vw !important;
    }
}


@media screen
and (max-device-width : 1920px)
{
    .container{
        max-width:1500px!important;
    }


    .customForm input {
        padding-left: 15px;
    }

    .LeftParent{
        height:540px;
    }


    .RightParent{
        height:538px;
    }



}


@media screen
and (max-device-width : 1500px)
{

    .hpSearchLocation .hpBtnLocationOpen {
        top: 6px;
    }

    .center #combo-box-demo2 {
        height: 16px !important;
    }

    .hpCategInput {
        height: 50px;
        border-radius: 15px!important;
    }


    .box .icon img{
        width:55px;
    }

    .lgUsr {

        .RightParentBg{
            height: calc(100vh - 22vh);
              }

              .RightParent{
                height: calc(100vh - 31vh);
              }

        }


.sliderText {
    margin-left:3%;
}

    .LeftParent .box {
        height: 95px;
    }

    .sliderText{
        margin-top: 66px !important;
        font-size: 32px;
        line-height: 32px;
    }



    .bgbox .icon {
        margin-top: 8px;
    }

    .box .textbox .title{
        font-size: 19px;
    }

    .box .textbox .location{
        font-size: 15px;
    }

    .box .textbox .date{
        font-size: 15px;
    }

    .customForm .dropdown-menu.show {
        width: 320px;
        transform: translate3d(-271px, 43px, 0) !important;
    }

    .LeftParent{
        height: 420px;
    }

    .RightParent{
        height: 420px;
    }

    .eventBtn {
        font-size: 17px !important;
    }

    .imageHead img {
        margin-top: -100px;
        margin-bottom: 60px;
    }


    .searchText{
        margin-top: 30px;
        margin-bottom: 25px;
    }

    .RightParent .yellowBox{
        height: 95px;
        border-radius: 30px;
    }

    .RightParent .textOffer {
        font-size: 23px;
    }

    .center {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .RightParent .vidBox {
        height: 95px;
        border-radius: 30px;
        display: flex;
        align-items: center;
    }

    .box{
        border-radius: 30px;
        height: 95px;
    }

    .customForm .input-group {
        margin-bottom: 10px;
    }

    .customForm .svg-inline--fa{
        right:17px;
    }

    .dropdown-menu .dropdown-item {
        font-size: 14px;
    }


    .customForm input, .customForm select{
        height: 50px !important;
        border-radius: 15px !important;
    }

    .box .textbox{
        padding: 13px 0px 10px 0px;
    }

}



@media screen
and (max-device-width :1600px){

    .lgUsr .RightParent {
        height: 475px;
    }

    .lgUsr .RightParentBg {
        height: 555px;
    }

    .sliderText{
        font-size:32px;
        line-height: 32px;
    }

    .sliderText {
        margin-top: 30px;
    }

    .eventBtn {
        font-size: 17px !important;
    }


    .shape {
        top: 14%;
    }

     .box, .LeftParent .box, .yellowBox{
        height: 110px;
        border-radius: 29px;
    }

    .vidBoxBtn, .vidBox{
        height:110px;
        border-radius: 29px;
    }

    .box .textbox {
        padding: 19px 0px 15px 0px;
    }

    .RightParent, .LeftParent {
        height: 472px;
    }

    .bgbox .icon{
        margin-top:2px;
    }

    .box .textbox .location {
        font-size: 16px;
    }

    .box .textbox .date {
        font-size: 16px;
    }

    .box .textbox .title {
        font-size: 20px;
    }
}




@media screen
and (max-device-width : 1350px)
{

    .hpSearchLocation .hpBtnLocationOpen {
        top: 6px;
    }


    .shape{
        top: 20%;
    }

    .box .textbox .title {
        font-size: 17px;
    }


    .sliderText{
        font-size: 30px;
        line-height: 30px;
    }


    .lgUsr .RightParent {
        max-width: 340px !important;
    }


    .box .textbox {
        padding: 18px 0px 10px 0px;
    }


    .box .textbox .location {
        font-size: 13px;
    }

   .LeftParent{
        height: 420px;
    }

    .RightParent{
        height: 420px;
    }

    .customForm .dropdown-menu.show {
        top: -41px !important;
        width: 79%;
    }


}

@media screen and (max-device-width: 1440px) {
    .modal-video-body{
    max-width: 60% !important;
    width: 60% !important;
}
.sliderText{
    font-size:32px;
    line-height: 32px;
    margin-top: 15px !important;
}
.overFowModal{
    overflow: auto !important;
    max-height: 650px;
    height: 650px;
}


}
@media screen and (max-device-width: 1280px) {
    .overFowModal{
        overflow: auto !important;
        max-height: 600px ;
        height: 550px;
    }
    .modal-video-body{
    max-width: 50% !important;
    width: 50% !important;
}
.customForm .input-group-append::before{
    content:"";
    border-left:1px solid rgba(255, 255, 255, 0.6);
    position: relative;
    left:-20px;
    height:15px;
}



}
@media screen
and (max-device-width : 1024px)
{


    .hpCategInput {
        height: 47px;
        border-radius: 15px!important;
    }

    .center #combo-box-demo2 {
        height: 13px !important;
    }


    .hpSearchLocation .hpBtnLocationOpen {
        top: 6px!important;
    }

    .rParentSize {
        padding-left:0!important;
        padding-right:0!important;
    }


    .lgUsr .RightParentBg {
        height: 424px!important;
        padding: 28px 0px 0px 0px!important;
    }

    .lgUsr .RightParent {
        max-width: 550px !important;
        height: 318px;
        margin: 0 auto;
    }

    .textOffer {
        font-size: 26px;
    }

    .vidBox {
        height: 110px;
        border-radius: 30px;
    }

    .vidBoxImg {
        height: 100px !important;
    }

    .LeftParent{
        padding-right:20px!important;
    }

    .vidBoxBtn {
        height: 122px;
    }

    .yellowBox{
        border-radius: 26px;
        height: 100px;
    }

    .sliderText {
        margin-top:60px!important;
    }

    .container-fluid{
      /*   overflow-x:hidden!important; */
    }

    .customForm {
        padding-left: 20px;
    }


    .btext,.ltext{
        font-size: 4vw;
        line-height:4vw;
    }

    .shape{
        width: 90%!important;
        perspective: none!important;
        margin-top: -80px!important;
    }

    .customForm input, .customForm select {

        border-radius: 15px !important;
    }

    .customForm input {
        font-size: 2vw !important;
    }


    .customForm .input-group {
        margin-bottom: 10px;
    }

    .customForm .dropdown-menu.show {
        width: 408px;
        transform: translate3d(-403px, 87px, 0px)!important;
    }

    .customForm  .dropdown-menu .dropdown-item {
        font-size: 14px;
    }

    .box .textbox {
        padding: 4px 0px 10px 0px;
    }

    .LeftParent{
        margin-top:25px;
        height: 435px;
    }

    .RightParent {
        display: block;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .center {
        display: block;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .parent {
        display: flex;
        flex-direction: column;
    }

    .eventBtn{
        margin-top:10px!important;
    }

    .sliderText .first {
        margin-top: -24px;
    }

    .searchText {
        margin-top: -20px;
        margin-bottom: 20px;
    }

    .customForm{
        max-width: 90%;
        margin: 0 auto;
    }

    .box .textbox .title {
        font-size: 3vw;
    }

        .firstItem{
            transform: none!important;
        }


    .imageHead img{
        height: 1345px;
        width: 1496px;
        object-fit: cover;
    }

    .imageHead{
        overflow: hidden;
    }

    .box .textbox .location {
        font-size: 20px;
    }

    .box .textbox .date{
        font-size: 20px;
    }

    .box {
        height: 99px;
    }

    .eventBtn{
        font-size: 2.0vw !important;
    }


}

@media screen
and (max-device-width :1280px) and (orientation : landscape)
{
    // .shape{
    //     perspective: none!important;
    // }
}


@media screen and (max-device-width : 1024px) and (orientation : landscape){
    .shape {
        width: 100% !important;
        max-width: 100% !important;
        margin-top: -13px !important;
    }

    .box .textbox .title {
        font-size: 15px!important;
    }

    .box .textbox .date {
        font-size: 15px!important;
    }

    .box .textbox .location {
        font-size: 13px!important;
    }

    .box .icon img {
        width: 41px;
        margin-left: -8px;
    }

    .imageHead img {
        margin-top: -32px;
    }

    .customForm input{
        font-size:13px!important;
    }

    .btext, .ltext {
        font-size: 2.5vw;
        line-height: 2.5vw;
    }

    .box .textbox .title {
        font-size: 2vw;
    }

    .box .textbox .location {
        font-size: 1.5vw;
    }

    .imageHead img {
        height: auto;
        width: 100%;
    }

    .box .textbox {
        padding: 18px 0px 10px 0px!important;
    }

    .RightParent .textOffer {
        font-size: 20px;
    }

    .RightParent{
        padding-right:10px!important;
    }

    .box .textbox .date {
        font-size: 1.5vw;
    }

    .LeftParent, .RightParent {
        margin-top:0;
        height: calc(100vh - 46vh);
    }

    .firstItem{
        padding: 20px!important;
    }

    .eventBtn {
        font-size: 1.4vw !important;
    }

    .customForm {
        max-width: 100%;
    }

    .customForm .dropdown-menu.show {
        width: 215px;
        transform: translate3d(-168px, 87px, 0px) !important;
    }


    .shape.col-lg-10{
        width:100%!important;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .searchText {
        margin-top: 0px;
    }
}



@media screen and (max-device-width : 767px)
{
    .adImg {
        border-radius: 20px;
        width: 100%;
        height: 200px !important;
      }
    .VideoBeforePlay{
        width: 50% !important;
    }
    .imgAndVideo{
        width: 100% !important;
    }
    .modal-video-body{
        max-width: 50% !important;
        width: 100% !important;
    }
    .firstItem{
        margin-top: 15px !important;
    }
    .descriptionStl{
        background-color: transparent !important;
        text-align: center !important;
        font-size: 13px ;
    
    }
   .modal-video-inner{
        vertical-align: top !important;
        padding-top: 100px !important;
        background-color: transparent !important;
        height: 50% !important;
    }
    .videoModal {

        position: absolute !important;
        top:auto !important;
        right:auto !important;
        left:auto !important;
        bottom:0 !important;


        width: 370vh !important;
        height: 300px !important;
        overflow-y: auto;

    align-content: center !important;

        justify-content: center !important;
        align-self: center !important;
        // z-index: 10000 !important;
        // overflow-y: scroll !important;
        // overflow-x: hidden !important;
        // color: red ;
        // height: 800px !important;
        // width: 100% !important;

      .modal-content {
        height: 100% !important;
        justify-content: center;
        color: #fff !important;
        width: 100% !important;
        background: transparent !important;

      }

      .textOfferPopup {
        font-size: 25px;
        font-family: $fontBold;
        color: #fff;
        // z-index: 10000000 !important;
      }
    }

    .box, .LeftParent .box, .yellowBox {
        border-radius: 15px;
    }

    .hpSearchLocation .hpBtnLocationOpen {
        right: -22px!important;
        top: 10px!important;
    }

    .hpCategInput .MuiAutocomplete-popupIndicator svg {
        font-size: 29px !important;
    }

    .hpCategInput .MuiAutocomplete-popupIndicator {
        top: 5px;
        right: -1px;
    }

    .react-datepicker-wrapper input {
        border: none!important;
    }

    .center #combo-box-demo2 {
        height: 5px !important;
    }

    .hpCategInput {
        height: 46px;
        border-radius: 15px!important;

        .MuiInputBase-root{
            height:47px;
        }

        .MuiAutocomplete-clearIndicator {
            margin-top: 10px !important;
            margin-right: 0px!important;
        }
    }

    .lgUsr .RightParentBg {
        padding:  20px 10px 0px 10px !important
    }

    .lgUsr .RightParent {
        padding-right: 10px!important;

    }



  .sliderText {
    font-size: 16px;
    line-height: 16px;
    margin-top: 75px!important;
}

.lgUsr .RightParent {
    height: 398px !important;
}

.lgUsr .RightParentBg {
    height: 448px !important;
    margin-top:25px;
}

    .LeftParent {
        height: 311px;
    }

    .shape, .parent {
        padding:0!important;
    }



    .LeftParent .row{
        padding:0!important;
        margin:0!important;
    }

    .bgbox .icon img{
        width:50px;
    }

    .center{
        padding:0!important;
    }

    .yellowBox {
        border-radius: 17px!important;
        height: 70px!important;
    }



    .box {
        height: 85px;
        border-radius: 15px;
    }

    .vidBoxBtn {
        height: 70px;
    }

    .vidBox {
        border-radius: 17px!important;
        height: 70px!important;
        z-index: 0;
    }

    .customForm input, .customForm select {
        height: 45px !important;
    }

    .customForm .input-group-append::before {
        left: -33px!important;
        height: 25px!important;
    }

    .customForm .arrowRight .input-group-append::before {
        left: 8px !important;
    }

    .customForm .input-group-append .btn, .input-group-prepend .btn {
        padding-right: 55px !important;
        height: 25px !important;
    }

    .customForm input {
        font-size: 15px !important;
    }

    .textOffer {
        font-size: 19px;
    }

    .customForm .input-group {
        margin-bottom: 6px;
    }

    .LeftParent .box {
        height: 70px;
    }

    .LeftParent {
        margin-top: 14px;
    }

    .customForm {
        max-width: 100%;
    }
    .shape {
        width: 100% !important;
        margin-top: -60px!important;
    }

    .btext, .ltext {
        font-size: 17px!important;
        line-height: 17px!important;
    }

    .box .textbox .title {
        font-size: 16px;
    }

    .box .textbox {
        padding: 9px 0px 10px 0px;
    }

    .lgUsr .box .textbox {
        padding: 14px 0px 10px 0px;
    }

    .bgbox .icon {
        color: #fff;
        margin-left: -17px;
        margin-top: -5px;
    }

   .lgUsr .bgbox .icon {
        color: #fff;
        margin-left: -31px;
        margin-top: 6px;
    }

    .box .textbox .location {
        font-size: 14px;
    }

    .box .textbox .date {
        font-size: 13px;
    }

    .eventBtn {
        font-size: 3.5vw !important;
    }

    .searchText {
        margin-top: 0px;
        margin-bottom: 7px;
    }



    .box {
        margin-bottom: 10px;
    }

    .bgcolor {
        height: 78px;
    }

    .customForm .svg-inline--fa {
        font-size: 16px;
        right: 36px;
        top: 5px;
    }

    .input-group-append .dropdown-toggle {
        right: 44px;
    }

    .customForm .dropdown-menu.show {
        width: 267px;
        transform: translate3d(-257px, 75px, 0px) !important;
    }

    .imageHead img {
        width: 971px;
        height: 967px;
        margin-top: 0;
        object-fit: cover;
    }

    .firstItem {
        padding: 25px 5px;
    }

    .customForm .input-group-append {
        margin-left: -1px;
        position: absolute;
        right: -24px;
        top: 10px;

    }


}



@media screen
and (max-device-width : 812px) and (orientation : landscape){

    .customForm .input-group{
        margin: 10px auto;
    }

    .customForm {
        max-width: 78%;
    }

    .customForm input, .customForm select {
        height: 10vh !important;
    }

    .LeftParent {
        height: calc(100vh - 81vh);
    }

    .firstItem{
        margin-top: -30px;
    }

}

@media screen
and (max-device-width : 736px) and (orientation : landscape){

    
    .LeftParent {
        height: calc(100vh - 40vh);
    }

    .sliderText {
        font-size: 3vw;
        line-height: 3vw;
    }

    .btext, .ltext {
        font-size: 3vw;
        line-height: 3vw;
    }

    .customForm input, .customForm select {
        height: 10vh !important;
    }



    .eventBtn {
        font-size: 2vw !important;
    }



    .box .textbox .title {
        font-size: 4vw;
    }

    .box .textbox .location {
        font-size: 3vw;
    }

    .box .textbox .date {
        font-size: 3vw;
    }



}


@media screen
and (max-device-width : 375px)
{

    .modal-video-inner{
        vertical-align: top !important;
        padding-top: 150px !important;
        background-color: transparent !important;
        height: 50% !important;
    }
    .videoModal {

        position: absolute !important;
        top:auto !important;
        right:auto !important;
        left:auto !important;
        bottom:0 !important;


        width: 370vh !important;
        height: 300px !important;
        overflow-y: auto;

    align-content: center !important;

        justify-content: center !important;
        align-self: center !important;
        // z-index: 10000 !important;
        // overflow-y: scroll !important;
        // overflow-x: hidden !important;
        // color: red ;
        // height: 800px !important;
        // width: 100% !important;

      .modal-content {
        height: 100% !important;
        justify-content: center;
        color: #fff !important;
        width: 100% !important;
        background: transparent !important;

      }

      .textOfferPopup {
        font-size: 25px;
        font-family: $fontBold;
        color: #fff;
        // z-index: 10000000 !important;
      }
    }
    .box .textbox {
        padding: 5px 0px 10px 0px;
    }

    .customForm .dropdown-menu.show {
        width: 267px;
        transform: translate3d(-228px, 75px, 0px) !important;
    }

  .LeftParent {
    height: 312px;
  }
}


.categoryInput {
  border-radius: 20px;
  background-color: rgba(45, 86, 131, 0.7) !important;
  width: 100%;
}

.adText {
  font-family: $fontBold;
  color: #093d77;
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 10px;
}


.businessSearchModal{
    width:90%!important;

}
.businessSearchModal .modal-content{
    min-height:365px!important;
}

.imgBuss {
  max-width: 100% !important;
  max-height: 100% !important;
}
.marginTop10{
    margin-top: 10px;
}
.height60{
    height: 60px;
}
.adImg {
    height: 400px ;
    border-radius: 20px;
  }
  .VideoBeforePlay{
    margin-top: 30px !important;
}  
.imgAndVideo{
    width: 100% !important;
    justify-content: center !important;
    text-align: center;
    // overflow: auto;
    // height: 100% ;
    // max-height: 600px;
}
.overFowModal{
    overflow: auto !important;
    // max-height: 600px;
    height: auto;
}
