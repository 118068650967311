@import "../../../Assets/css/variable";


$promoBarColor :#27a4b5!important;


.promoBar btn{
    margin-top: 13px;
}

.promoBar1 .backgroundLeft{
    background: url('../../../Assets/img/offerImage1.png') no-repeat transparent;
    width: 100%;
    max-width: 1200px;
    background-position: center;
    height: 310px;
    box-shadow: 0px 19px 56.44px 11.56px rgba(29, 33, 33, 0.51);
    border-radius: 50px;
}


.promoBar2 .backgroundLeft{
    background: url('../../../Assets/img/offerImage2.png') no-repeat transparent;
    width: 100%;
    max-width: 1200px;
    height: 310px;
    background-position: center;
    box-shadow: 0px 19px 56.44px 11.56px rgba(29, 33, 33, 0.51);    margin: -1% auto;
    border-radius: 50px;
}

.icons{
    display: flex;
    position: absolute;
    bottom: 0;
    right: 10%;

}

.promoButtons{
    display: flex;
    flex-direction: column;
}

.promoButtons2{
    position: absolute;
    width: 25%;
    right: 0;
    top: 105px;
    display: flex;
    flex-direction: column;
}

.appBtn{
    width:80%;
}

.promotexts{
    padding:70px 100px;
}

.promoTextB, .promoTextL{
    font-size: 41px;
    line-height: 40px;
    letter-spacing: -2px;
    color:#fff;
}

.promoTextB{
    font-family: $fontBold;
}



.promoTextL{
    font-family: $fontLight;
}



.appBtn{
    cursor: pointer;
    transition: 0.3s;

    &:hover{
        transform: scale(1.05);
    }
}

.promoBar1{
    z-index: 5;
    width: 70%;
    margin: -17% auto;
    margin-bottom: 50px;
    position: relative;
}


.promoBar2{
    z-index: 16;
    width: 56%;
    margin: 0 auto;
    margin-bottom: 50px;
    position: relative;
    top: 50px;
}

.contentPromo{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
}




.promoBtn{
    background: #093d77!important;
    padding: 12px 80px !important;
    border-radius: 50px!important;
    color:#fff!important;
    font-size: 17px!important;
    position: absolute;
    bottom: 80px;
    left: 98px;
    box-shadow: 0px 19px 31.28px 14.72px rgba(29, 33, 33, 0.22);
    border:none!important;
    font-family: $fontBold;


    &:hover{
        background: #042244!important;


    }
}
.event-signUp-btn{
    width: 100% !important;
    background-color: red;
}


.icons img{
    width: 110px;
    position: relative;
    right: 0;
    cursor: pointer;

}

.google{
    width: 80px;

}


@media screen
and (min-device-width : 1921px)
and (max-device-width : 3000px)
{
    .promoBar2 {
        width: 70%;
    }

    .promoBar1 {
        margin-top: -32%
    }

}


@media screen
and (max-device-width : 1680px)
{

    .promoBar2 {
        width: 55%;
    }

    .promoBar2 {
        top: -35px;
    }

}

@media screen
and (max-device-width : 1500px)
{
    .promoBar1{
        margin: -16% auto;
        margin-bottom: 50px;
        width: 89%;

    }

    .promoBar1 .backgroundLeft, .promoBar2 .backgroundLeft{
        height: 240px;
        max-width: 751px;
    }

    .backgroundLeft {
        width: 1140px;
    }

    .promoTextB, .promoTextL {
        font-size: 37px;
        line-height: 37px;
    }

    .promotexts {
        padding: 62px 100px;
    }


    .promoBtn{
        font-size:20px!important;
        padding: 7px 47px !important;
        bottom: 40px;
    }


    .promoButtons{
        width:50%;
    }

    .appBtn {
        width: 90%;
    }

    .promoBar2 {
        width: 71%;
        top: -24px;
    }

    .promoButtons2 {
        width: 18%;
        right: 49px;
        top: 82px;
    }

    .promoButtons{
        position: relative;
        right: -69px;

    }

}


@media screen
and (max-device-width : 1280px)
{
    .promoBar1 {
        margin: -30% auto;
        margin-bottom:50px;
        width: 83%;
    }

    .backgroundLeft {
        width: 1059px;
    }


    .promoBar2 {
        width: 83%;
        top: 54px;
    }

    .promoButtons{
        right: 0;
        position: relative;
    }

    .promoButtons2 {
        width: 22%;
    }

}

@media screen
and (max-device-width :1280px) and (orientation : landscape)
{
    .promoBar1 {
        margin-top: -250px;
        margin-bottom:100px;
    }
}

@media screen
and (max-device-width : 1024px)
{

    .promoTextB, .promoTextL {
        font-size: 53px;
        line-height: 55px;
    }

    .promoBtn{
        font-size: 23px !important;
        bottom: 30px;
        left: 58px;
    }


    .promoBar1 .container-fluid{
        overflow: inherit!important;
    }


    .promoBar2 .container-fluid{
        overflow: inherit!important;
    }

    .promoBar1 .backgroundLeft{
        background-size: cover;
        width: 600px;
        height: 210px;
    }

    .promoBar2 .backgroundLeft{
        background-size: cover;
        width: 600px;
        height: 210px;
        margin-left: 69px;
    }

    .promoBar2 .backgroundLeft .promoBtn{
        left: 57px;
    }

    .promoBar1{
        width: 95%;
    }

    .promoBar2{
        width: 80%;
        margin: 0 auto;
        left: -30px;
        top:-30px;
    }

    .promoButtons{
        position: absolute;
        right:140px;
        width: 136px;
        top: -50px;
    }

    .promoButtons2{
        right: 21px;
        top: 42px;
    }

    .promoBtn {
        font-size: 18px !important;
        padding: 13px 54px !important;
        border:none!important;
        font-family: $fontBold;
    }

    .promoTextB, .promoTextL {
        font-size: 37px;
        line-height: 33px;
    }

    .appBtn {
        width: 134px;
    }

    .promotexts {
        padding: 43px;
    }


    .promoBar1 {
        margin-top: -300px;
    }

}
@media screen and (max-device-width: 1440px) {

    .promoBar1 {
        height: 300px;
        margin-top: 0;
        margin-bottom: 100px;
    }
}
@media screen and (max-device-width: 1280px) {

    .promoBar1 {
        height: 300px;
        margin-top: 0;
    }
}

@media screen
and (max-device-width : 1024px) and (orientation : landscape){
    .promoBar1 {
        margin-top: -156px;
    }

    .promoBar2 .backgroundLeft {
        margin-left: 185px;
    }

    .promoBar2 .backgroundLeft .promoBtn {
        left: 55px;
        
    }

    .promoBar2 {
        left: -83px;
        top: 18px;
        width: 68%;
    }

}





@media screen
and (max-device-width : 767px) {
    .promoBar1 {
        margin-top: 0px;
    }

    .promoBar2 {
        margin-top: 50px!important;
    }

    .promoBar1 .backgroundLeft {
        width: 357px;
        height: 142px;
    }

    .promoBar2 .backgroundLeft {
        width: 357px;
        height: 142px;

    }

    .promotexts {
        padding: 16px;
    }

    .promoTextB, .promoTextL {
        font-size: 23px;
        line-height: 21px;
        letter-spacing: 0;
    }

    .promoBtn {
        font-size: 14px !important;
        padding: 8px 30px !important;
        margin-left: -28px;
    }

    .appBtn {
        width: 90px;
        margin-bottom: 5px!important;
    }

    .promoBar1 {
        width: 92%;
        z-index: 0;
    }

    .promoBar2 {
        width: 92%;
    }


    .promoButtons {
        position: absolute;
        right: 0;
        width: 90px;
        top: -38px;
    }

    .promoBar1 .backgroundLeft{
        border-radius: 30px;
    }

    .promoBar2 .backgroundLeft{
        border-radius: 30px;
    }

    .contentPromo{
        width: 100%;
        margin-left: -8px;
    }

    .promoBar2 .backgroundLeft{
        margin-left:0;
    }

    .promoBar2 {
        margin-top: -40px;
        width: 90%;
        left:15px;
    }

    .promoBar2 .backgroundLeft .promoBtn {
        left: 58px;
    }

    .promoButtons2 {
        right: 102px;
        top: 42px;
        display: flex;
        flex-direction: column;
        width:0;
    }

}

@media screen
and (max-device-width : 812px) and (orientation : landscape){
    .promoBar2 .backgroundLeft {
        margin-left: 94px;
    }

    .promoBar2{
        left: -29px;
    }

    .promoBar2 .backgroundLeft .promoBtn {
        left: 157px;
    }
}


@media screen
and (max-device-width : 375px) {

    .promoBar2{
        width: 92%;
    }

    .promoBar1 {
        width: 100%;
        left: 0;
        right: 0;
        margin-top: 10px;
    }

    .promoBar1 .backgroundLeft {
        width: 345px;
    }



}


@media screen
and (max-device-width : 320px) {
    .promoBar1 .backgroundLeft {
        width: 290px;
    }

    .promoTextB, .promoTextL {
        font-size: 18px;
        line-height: 17px;
    }

    .promoBtn {
        font-size: 11px !important;
    }

}