@import "../../../Assets/css/variable";
$blueevents: #083d77 !important;
$yellowEvents: #f4d35e !important;
$greenEvents: #00b4b1 !important;
$iconsColor: #0ee6e0 !important;
$inputsColor: #f7f7f7 !important;
$inputBorderColor: #0d878b !important ;
$headerText: #343857 !important;
$texts: #18b3ba !important;

.modal {
  background: #2125294f !important;
}

.example-appear {
  opacity: 0.01;
}
.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}
.modal-header > .close {
  color: white !important;
  font-size: 35px !important;
  opacity: 2 !important;
  font-weight: 500 !important;
  padding: 10px 10px !important;
}

.modal-Body {
  padding: 0 2rem 0rem 2rem !important;
}

.footer-qua {
  padding: 2rem !important;
  z-index: 0 !important;
}

.btnCreate {
  color: #093d77 !important;
  font-size: 20px !important;
}

.mb-2 > .mr-sm-2 > .mb-sm-0 > .fg-1 {
  display: flex !important;
  border-width: solid !important;
  width: auto !important;
  height: auto !important;
  margin-right: 0 !important;
}

.append-image {
  margin-right: 6px;
  border-radius: 0 5px 5px 0 !important ;
  background: #e8f0fe !important;
  border: 1px solid #ced4da;
  border-top-right-radius: 22px !important;
  border-bottom-right-radius: 22px !important;

  span {
    color: #009d9b !important;
  }
}

.inputs-stl-drop{
  background-color: rgb(6, 45, 114) !important;
  border-color: transparent;
  border-radius: 22px !important;
  height: 50px !important;
  padding-left: 20px !important;
  color: #00202e;
}


.inputs-stl {
  background-color: rgb(232, 240, 254) !important;
  border-color: transparent;
  color: #00202e;
  border-radius: 15px !important;
  height: 50px !important;
  padding-left: 20px !important;
  font-size: 15px!important;
}
.inputs-stl::placeholder {
  color: rgb(87, 87, 87) !important;
  font-weight: 100 !important;
}
#calendar::placeholder{
  color: rgb(87, 87, 87) !important;
  font-weight: 100 !important;
}
.btnAccout button {
  background: #15836d !important;
  font-size: 17px !important;
  padding: 0.3em 3em !important;
  border-radius: 50px !important;
  border: none !important;
  transition: 0.3s;

  &:hover {
    background: #094136 !important;
  }
}

.Fform {
  margin-top: 10px;

  .btnContinue {
    margin-top: 50px;
    margin-bottom: 50px !important;
    font-size:16px!important;
  }
}

.btnContinue {
  z-index: 0 !important;
  background: #093d77 !important;
  font-size: 20px !important;
  padding: 0.5em 3em !important;
  border-radius: 50px !important;
  border: none !important;
}

.modal-content {
  border: none !important;
  border-radius: 20px !important;
}

.invalid-feedback {
  font-size: 85%;
}

.log-btn {
  z-index: 0 !important;
  padding: 0 !important;
  margin-top: 10px;
  display: flex !important;
  /* width: 100%; */
  justify-content: center !important;
  max-width: 100%;
}
.log-btn-p {
  z-index: 0 !important;
  padding: 1rem 0 0 0 !important;
}
.Names-top {
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: center !important;
  max-width: 100%;
}

.text-ageenments {
  font-size: 15px !important;
}
.selectet-Date {
  justify-content: center;
}

.Fform select {
  background-position: calc(100% - 19px) 25px, calc(100% - 10px) 25px, 100% -3px !important;
}

.date-picker-mine {
  display: flex;
  width: 100% !important;
  margin-right: 5px !important;

  .noBorder-modal {
    z-index: 50 !important;
    margin-right: 5px !important;
    position: relative;
    height: 40px;

    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    border-right: 0 !important;
  }
}
.react-datepicker-wrapper {
  width: 100%;
}
.border-p {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
}
.border-r1 {
  width: 40%;
  float: left !important;
  position: relative;
  //align-self:center;
  justify-content: flex-start !important;
}
.border-l1 {
  border: solid;
  border-color: royalblue;
  width: 40%;
  float: right !important;
  position: relative;
  // align-self:center;
  justify-content: flex-end !important;
}

// .calendarImg {
// }

.border-paragraph {
  padding-top: 10px;
  font-size: small;
  align-self: flex-end;
}
.logo_image > .my_logo {
  height: 60px;
  width: 60px;
  position: absolute;
  margin: 0 !important;
  top: 0;
  margin-left: -1.8rem !important;
}
.header-p {
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height:63px;
}
.head-2 {
  background: linear-gradient(90deg, #159957 -83%, #155799 100%);
  width: 50%;
  border-radius: 0 !important;
}
.head-1 {
  background: linear-gradient(90deg, #159957 0%, #155799 230%);
  width: 50%;
  border-radius: 0 !important;
}
.agree {
  display: flex;
  margin-top: 20px;
  .text-ageenments {
    color: #093d77;
    font-weight: 600 !important;
    font-size: 11px;
  }
}

.newAcc{
  margin-bottom: 20px !important;
  transition:0.3s!important;
  color: #007bff !important;
  height: 30px !important;
  width: fit-content !important;
  background-color: transparent !important;
  border: none !important;
  align-self: center !important;
}

.newAcc:hover{
  text-decoration:none!important;
  transform: scale(1.05);

}

.mf {
  display: flex;
  padding: 10px 0 10px;
  .Check-M {
    margin-right: 10px;
  }
  .gender-latter {
    color: #093d77;
    font-weight: 750;
  }
}
.LogIn-top-text {
  padding: 20px 0 0 0 !important ;
  width: 100% !important;
  height: auto !important;
  text-align: center;
  font-size: 25px;
  margin: 0 !important;
  color: $headerText;
  margin-bottom: -15px!important;
  font-weight: 50px !important;

  .header-txt {
    font-weight: 600 !important;
    font-size:25px!important;
  }
}
.forgot-pass {
  color: #007bff !important ;
  // border: solid !important;
  font-size: 14px !important ;
  text-decoration: underline;
  padding: 0 !important;
  margin-top: 20px;
  transition: 0.3s!important;

  &:hover{
    text-decoration: none!important;
    transform: scale(1.05);
    color: #0056b3!important;
  }

  .forgot-parinte {
    border: solid;
    font-size: 20px;
    padding: 1rem !important;
  }
}
.col > .order-12 {
  z-index: 0 !important;
  font-family: none !important;
}
.react-datepicker-popper {
  z-index: 10 !important;
}
.react-datepicker__month-container {
  z-index: 50 !important;
}
.form-index {
  z-index: 100 !important;
}

.adding-input-btn {
  display: flex !important;
  justify-content: space-around !important;
  padding: 60px;

  .change-btn2 {
    align-self: flex-end !important;
    margin-left: 10px;
  }
  .change-btn1 {
    margin-right: 10px;
  }
}

.birth {
  background-color: #e8f0fe !important;
  border-color: transparent;
  color: #00202e;
  border-top-left-radius: 22px !important;
  border-bottom-left-radius: 22px !important;
  height: 60px !important;
  padding-left: 20px !important;
}

.back-Btnn {
  margin-top: -54px;
  align-self: flex-end;
  margin-right: 1rem !important;
  height: 40px;
  width: 40px;
  position: absolute;
  z-index: 5;
  margin-left: 1.5rem !important;
  color: rgb(9, 94, 87) !important;
  cursor: pointer;
  text-align: center;
  font-weight: 700;
  font-size: 15px;
  // font-family: Verdana, Geneva, sans-serif;

}

.modalBtn {
  margin: 20px  !important;
  background: #093d77 !important;
  font-size: 17px !important;
  padding: 0.3em 2.5em !important;
  border-radius: 50px !important;
  border: none !important;
}
.labelCalendar{
  width: 100% !important;
}
.datePickerSignUp {
  width: 100% !important;
  z-index: 1000 !important;
  border: solid gainsboro !important;
  border-width: 1px;
  border-bottom-left-radius: 22px;
  border-top-left-radius: 22px;
  background-color: rgb(232, 240, 254);
  height: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  z-index: 999 !important;
  ::placeholder{
    color: rgb(87, 87, 87) !important;
    
  }
}

.datePickerSignUp-chld {
  width: 100% !important;
  z-index: 9999 !important;
  border: none !important;
  background-color: rgb(232, 240, 254) !important;
  // height: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  z-index: 999 !important;
}
@media screen and (max-device-width : 767px){
  .LogIn-top-text{
    font-size:20px;
  }

  .inputs-stl{
    height: 42px !important;
  }

  .modalBtn{
    font-size:15px!important;
  }

}
.drop-down-sign{
  // border: solid black;
  background-color: rgb(14, 55, 116);

  border-radius: 15px !important;
}

.adressInput .location-search-input {
  width: 100%;
  border: none;
  background-color: #e8f0fe !important;
  border-radius: 15px;
  margin-top: 10px;
  padding-right: 10px;
}
.adressInput .borderSearchBar {
  color: #383838 !important;
  background-color: #e8f0fe !important;
  border: 1px solid #ced4da;
  margin-top: 24px;
  height: 60px !important;
  display: flex;
  align-items: center;
  ::placeholder{
    color: rgb(87, 87, 87) !important;
  }
}

.adressInput .location-search-input {
  color: #000000 !important;
  margin: 0px;
  padding: 15px;
}
.adressInput .suggestion-item {
  color: #000000 !important;
}
.adressInput .suggestion-item--active {
  color: #000000 !important;
  background-color: #e8f0fe !important;
  width: 100%;
}
.adressInput .zingMapComponent .autocomplete-dropdown-container {
  position: absolute;
  z-index: 9999;
  display: block;
  border: none;
}
.calendarLabel {
  font-family: $fontBold;
  color: #093d77;
}
